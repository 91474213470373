import * as types from "./actionTypes";

export const loginUserAction = (user: {
  username: string;
  password: string;
}) => {
  return {
    type: types.LOGIN_USER,
    ...user,
  };
};

export const loginUserFail = (error: any) => {
  return {
    type: types.LOGIN_USER_FAIL,
    payload: error,
  };
};

export const loginUserSuccess = (user: any) => {
  return {
    type: types.LOGIN_USER_SUCCESS,
    payload: user,
  };
};

export const loginUserProfileAction = () => {
  return {
    type: types.LOGIN_PROFILE_USER,
  };
};

export const loginUserProfileFail = (error: any) => {
  return {
    type: types.LOGIN_PROFILE_FAIL,
    payload: error,
  };
};

export const loginUserProfileSuccess = (user: any) => {
  return {
    type: types.LOGIN_PROFILE_SUCCESS,
    payload: user,
  };
};

export const resetUserAction = (username: any) => {
  return {
    type: types.RESET_USER,
    username,
  };
};

export const resetUserFail = (error: any) => {
  return {
    type: types.RESET_USER_FAIL,
    payload: error,
  };
};

export const resetUserSuccess = (user: any) => {
  return {
    type: types.RESET_USER_SUCCESS,
    payload: user,
  };
};
