import { Card, Col, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { Separator } from "../separator";

const Spinner = (props: any) => {
  const { tip } = props;
  return (
    <Card key={`Detail-spinner`} className={"card-product-detail"}>
      <Separator marginTop={10}>
        <div className={"custom-spinner-style"}>
          <Spin size="large" tip={tip && tip}></Spin>
        </div>
      </Separator>
    </Card>
  );
};

export default Spinner;
