export const SAVE_QUOTE = "SAVE_QUOTE";
export const SAVE_QUOTE_FAIL = "SAVE_QUOTE_FAIL";
export const SAVE_QUOTE_SUCCESS = "SAVE_QUOTE_SUCCESS";

export const GET_QUOTE_SEARCH = "GET_QUOTE_SEARCH";
export const GET_QUOTE_SEARCH_FAIL = "GET_QUOTE_SEARCH_FAIL";
export const GET_QUOTE_SEARCH_SUCCESS = "GET_QUOTE_SEARCH_SUCCESS";

export const ADD_QUOTE_TO_QUOTE = "ADD_QUOTE_TO_QUOTE";
export const ADD_QUOTE_TO_QUOTE_FAIL = "ADD_QUOTE_TO_QUOTE_FAIL";
export const ADD_QUOTE_TO_QUOTE_SUCCESS = "ADD_QUOTE_TO_QUOTE_SUCCESS";

export const CLEAN_QUOTE_TO_QUOTE = "CLEAN_QUOTE_TO_QUOTE";
export const CLEAN_QUOTE_TO_QUOTE_FAIL = "CLEAN_QUOTE_TO_QUOTE_FAIL";
export const CLEAN_QUOTE_TO_QUOTE_SUCCESS = "CLEAN_QUOTE_TO_QUOTE_SUCCESS";
