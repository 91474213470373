import { call, put, takeLatest } from "redux-saga/effects";
import { apiGet } from "../../shared/ApiService";
import {
  getResumeActionFail,
  getResumeActionSuccess,
  getUserInfoActionFail,
  getUserInfoActionSuccess,
} from "./actions";
import { GET_USER_INFO, GET_RESUME } from "./actionTypes";

function* onGetUserInfo(payload: any) {
  try {
    const response: object = yield call(() => {
      return Promise.resolve(
        apiGet({ url: payload.userUrl, unauthorizedCallback: () => {} })
      );
    });
    yield put(getUserInfoActionSuccess(response as any));
  } catch (error: any) {
    yield put(getUserInfoActionFail(error.response));
  }
}

function* onGetResume(payload: any) {
  try {
    const response: object = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: payload.userUrl,
          unauthorizedCallback: () => {},
        })
      );
    });
    yield put(getResumeActionSuccess(response as any));
  } catch (error: any) {
    yield put(getResumeActionFail(error.response));
  }
}

function* UserInfoSaga() {
  yield takeLatest(GET_USER_INFO, onGetUserInfo);
  yield takeLatest(GET_RESUME, onGetResume);
}

export default UserInfoSaga;
