
import * as types from "./actionTypes";

const initialState = {
  user: undefined,
  resume: undefined,
};

const BalanceReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  const actionType = action.type;
  const actionPayload = action.payload;
  switch (actionType) {
    case types.GET_USER_INFO:
    case types.GET_USER_INFO_SUCCESS:
      state = { ...state, user: actionPayload };
      break;
    case types.GET_USER_INFO_FAIL:
    case types.GET_RESUME:
    case types.GET_RESUME_SUCCESS:
      state = { ...state, resume: actionPayload };
      break;
    case types.GET_RESUME_FAIL:
      break;
  }

  return state;
};

export default BalanceReducer;
