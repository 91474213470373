import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormInstance,
  Input,
  Image,
  message,
  Row,
} from "antd";
import AuthenticatedRoute from "../../core/routes/AuthenticatedRoute";

import { Paths } from "../../config/paths";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { LoginFormProps, ReduxType } from "../../shared/types";
import { InputText } from "../../components/inputs";
import { LoginFooterComponent } from "../../components/base";

import loginUserLogo from "../../assets/icon/loginUser.png";
import loginPassLogo from "../../assets/icon/loginPass.png";
import { isLoggedIn } from "../../core/auth/AuthService";

import {
  loginUserAction,
  loginUserProfileAction,
} from "../../store/users/actions";
import { useDispatch } from "react-redux";
import { Spinner } from "../../components/spinner";
import { FormItemComponent } from "../../components/forms/formItemComponent";

const LoginPage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isTriggerProfile, setIsTriggerProfile] = useState<boolean>(false);
  const navigate = useNavigate();
  const formRef = React.createRef<FormInstance>();

  const { userData } = useSelector((state: ReduxType) => {
    return state;
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoggedIn()) {
      getProfileAndRedirect();
    }
  }, [userData.user]);

  useEffect(() => {
    if (userData.userProfile) {
      navigate(Paths.ROOT);
    }
  }, [userData.userProfile]);

  const getProfileAndRedirect = async () => {
    try {
      setIsLoading(true);
      if (!isTriggerProfile) {
        setIsTriggerProfile(true);
        await dispatch(loginUserProfileAction());
      }
    } catch (error) {
      message.error("Se ha producido un error. Verifique sus credenciales");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (values: LoginFormProps) => {
    try {
      setIsLoading(true);
      emptyField();
      await dispatch(
        loginUserAction({
          username: values.username,
          password: values.password,
        })
      );
      console.log('Login request')
    } catch (error) {
      message.error("Se ha producido un error. Verifique sus credenciales");
    } finally {
      setIsLoading(false);
    }
  };

  const emptyField = () => {
    if (formRef && formRef.current) {
      formRef.current.validateFields();
    }
  };

  return (
    <AuthenticatedRoute path={Paths.LOGIN} login withHeader withLogo>
      {!userData.loading ? (
        <>
          <Row justify="center" align="middle" style={{ marginTop: 20 }}>
            <Col xs={20} xl={10}>
              <Form
                name="basic"
                initialValues={undefined}
                onFinish={handleSubmit}
                layout="vertical"
                ref={formRef}
              >
                <FormItemComponent
                  inputName={"username"}
                  rules={[{ name: "required" }]}
                  message={`Por favor, ingresá tu usuario.`}
                >
                  <InputText
                    enabled
                    required
                    prefix={
                      <Image
                        style={{ objectFit: "contain" }}
                        src={loginUserLogo}
                        preview={false}
                      />
                    }
                    style={{ height: 45 }}
                    showPlaceholder
                    type={"text"}
                    name={"username"}
                    placeholder={"Usuario"}
                  />
                </FormItemComponent>

                <FormItemComponent
                  inputName={"password"}
                  rules={[{ name: "required" }]}
                  message={`Por favor, ingresá tu contraseña.`}
                >
                  <Input.Password
                    prefix={
                      <Image
                        style={{ objectFit: "contain" }}
                        src={loginPassLogo}
                        preview={false}
                      />
                    }
                    placeholder={"Contraseña"}
                    style={{ height: 45 }}
                  />
                </FormItemComponent>

                <LoginFooterComponent />

                <Row>
                  <Col span={24}>
                    <Button
                      disabled={isLoading}
                      loading={isLoading}
                      type="primary"
                      htmlType="submit"
                      className={"custom-button custom-button-blue "}
                    >
                      Ingresar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </>
      ) : (
        <Spinner />
      )}
    </AuthenticatedRoute>
  );
};

export default LoginPage;
