import { InputNumberType } from "../../../shared/types";

export const InputTypePrecision: any = {
  [InputNumberType.Number0]: { type: "number" },
  [InputNumberType.Number2]: { type: "number", precision: 2 },
  [InputNumberType.Number4]: { type: "number", precision: 4 },
  [InputNumberType.Decimal0]: { type: "number" },
  [InputNumberType.Decimal2]: { type: "number", precision: 2, step: "0.01" },
  [InputNumberType.Decimal3]: { type: "number", precision: 3, step: "0.001" },
  [InputNumberType.Decimal4]: { type: "number", precision: 4, step: "0.0001" },
  [InputNumberType.Percentage0]: { type: "percentage" },
  [InputNumberType.Percentage2]: { type: "percentage", precision: 2 },
  [InputNumberType.Percentage4]: { type: "percentage", precision: 4 },
};
