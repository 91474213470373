import { ContainerOutlined, DesktopOutlined } from "@ant-design/icons";
import { Col, Collapse, Image, Menu, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HeaderComponent } from "../../components/base";
import { Paths } from "../../config/paths";
import { AuthenticatedRouteProps } from "../../shared/types";
import { isLoggedIn } from "../auth/AuthService";
import menuLogo from "../../assets/logo/menuLogo.png";
import { MainMenu } from "../../components/menu/mainMenu";

const AuthenticatedRoute = (props: AuthenticatedRouteProps) => {
  const {
    path,
    children,
    dashboard,
    withLogo,
    withDashboardLogo,
    withHeader,
    headerNavigationName,
    goBack,
    login,
  } = props;

  const [loading, setLoading] = useState(true);
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const [width, setWidth] = useState<number>(window.innerWidth);

  const navigate = useNavigate();

  useEffect(() => {
    if (
      !isLoggedIn() &&
      window.location.pathname !== Paths.LOGIN &&
      window.location.pathname !== Paths.RESET
    ) {
      return navigate(Paths.LOGIN);
    }
    setLoading(false);
  }, [path]);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <>
      {!loading && (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              overflowY: "auto",
              height: "100%",
              width: "100%",
            }}
          >
            {withHeader && (
              <HeaderComponent
                width={width}
                withLogo={withLogo}
                dashboard={dashboard}
                login={login}
                withDashboardLogo={withDashboardLogo}
                collapsed={collapsed}
                headerNavigationName={headerNavigationName}
                goBack={() => goBack && goBack()}
                toggleCollapsed={() => toggleCollapsed()}
              />
            )}
            {children}
          </div>

          {!collapsed && (
            <MainMenu
              collapsed={collapsed}
              setCollapsed={() => setCollapsed(!collapsed)}
            />
          )}
        </>
      )}
    </>
  );
};

export default AuthenticatedRoute;
