import React, { useEffect, useRef, useState } from "react";

import { Col, Row, Table } from "antd";
import { HeaderComponent } from "../../components/base";
import AuthenticatedRoute from "../../core/routes/AuthenticatedRoute";
import { Paths } from "../../config/paths";

import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FormTypeEnum, ProductDataProps, ReduxType } from "../../shared/types";
import { API_URL } from "../../config/general-config";
import { Endpoints } from "../../config/endpoints";
import { Pagination } from "antd";
import { SimpleSearchForm } from "../../components/forms";
import {
  getProductPriceListAction,
  getProductsSearchAction,
  setProductAction,
} from "../../store/products/actions";
import { setClientAction } from "../../store/clients/actions";
import { TableRowSelection } from "antd/lib/table/interface";

const ProductPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentSearchValue, setCurrentSearchValue] = useState<
    string | undefined
  >();
  const [paginationHeight, setPaginationHeight] = useState(0);
  const paginationRef = useRef<any>(null);
  const [selectedRowKeys, setselectedRowKeys] = useState<any[]>([]);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const { state }: any = useLocation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { productData } = useSelector((state: ReduxType) => {
    return state;
  });

  const { clientData } = useSelector((state: ReduxType) => {
    return state;
  });

  useEffect(() => {
    if (paginationRef && paginationRef.current)
      setPaginationHeight(paginationRef.current.clientHeight);
  }, [paginationRef]);

  useEffect(() => {
    if (!productData.productsSearch) {
      doRequest(1, currentSearchValue);
    }
  }, []);

  useEffect(() => {
    if (!productData.productPriceList) {
      doRequestForPrice();
    }
  }, []);

  useEffect(() => {
    if (currentPage) {
      doRequest(currentPage, currentSearchValue);
    }
  }, [currentPage]);

  useEffect(() => {
    if (currentSearchValue) {
      doRequest(1, currentSearchValue);
    }
  }, [currentSearchValue]);

  const doRequestForPrice = async () => {
    await dispatch(getProductPriceListAction());
  };

  const doRequest = async (page: number, searchValue: string | undefined) => {
    let url = `${API_URL}/${Endpoints.PRODUCT_SEARCH}?cliente=${clientData.client._id}`;
    if (searchValue) {
      url += `&grupo=${searchValue.replace(/\s/g, "+")}`;
    }
    if (page) {
      url += `&page=${page}&size=12`;
    }

    await dispatch(getProductsSearchAction(url));
  };

  const rowSelection: TableRowSelection<any> = {
    onSelect: async (record) => {
      setselectedRowKeys([record.key]);
      await dispatch(setProductAction(record));
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name,
    }),
    selectedRowKeys: selectedRowKeys,
  };

  const columns = [
    {
      title: "Rubro",
      dataIndex: "rubro",
      render(text: any, record: any) {
        return {
          props: {
            style: { color: "#706F6E" },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Producto",
      dataIndex: "descripcion",
      render(text: any, record: any) {
        return {
          props: {
            style: { color: "#706F6E" },
          },
          children: <div>{text}</div>,
        };
      },
    },
  ];

  const dataSourceTable: any[] | undefined =
    productData.productsSearch &&
    productData.productsSearch.data &&
    productData.productsSearch.data.map(
      (col: ProductDataProps, index: number) => {
        return {
          key: index,
          _id: col._id,
          rubro: col.grupo.codigo,
          codigo: col.codigo,
          descripcion: col.descripcion,
        };
      }
    );

  return (
    <AuthenticatedRoute
      path={Paths.PRODUCT}
      withHeader
      headerNavigationName={
        state && state.formType == FormTypeEnum.Quote
          ? "Nueva Cotización"
          : "Nuevo Pedido"
      }
      goBack={() =>
        navigate(
          (productData.product && Paths.NEWPRODUCT) || (state && state.from),
          {
            state: {
              formType: state.formType,
            },
          }
        )
      }
    >
      {!isLoading && (
        <>
          <SimpleSearchForm
            inputName={"rubroSearch"}
            placeholder={"Rubro"}
            onSearch={(value) => setCurrentSearchValue(value)}
          />

          <Row
            align="middle"
            justify="space-between"
            style={{ marginBottom: paginationHeight }}
          >
            <Col span={24}>
              <Table
                id={"custom-table"}
                rowSelection={{
                  type: "radio",
                  ...rowSelection,
                }}
                rowClassName="row-selection"
                onRow={(record) => ({
                  onClick: async () => {
                    setselectedRowKeys([record.key]);
                    await dispatch(setProductAction(record));
                  },
                })}
                loading={productData && productData.loading}
                columns={columns}
                dataSource={dataSourceTable}
                pagination={false}
              />
            </Col>
          </Row>

          {productData.productsSearch &&
            productData.productsSearch.data &&
            productData.productsSearch.data.length > 0 && (
              <div
                style={{
                  padding: 10,
                  backgroundColor: "#f0f0f0",
                  position: "fixed",
                  bottom: 0,
                  width: "100%",
                }}
                ref={paginationRef}
              >
                <Pagination
                  pageSize={12}
                  style={{ float: "right" }}
                  current={currentPage}
                  onChange={(page) => setCurrentPage(page)}
                  total={productData.productsSearch.total}
                  showSizeChanger={false}
                />
              </div>
            )}
        </>
      )}
    </AuthenticatedRoute>
  );
};

export default ProductPage;
