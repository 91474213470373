import { combineReducers } from "redux";
import BalanceReducer from "./balance/reducer";
import ClientReducer from "./clients/reducer";
import ConfigurationReducer from "./configuration/reducer";
import ProductReducer from "./products/reducer";
import RubroReducer from "./rubro/reducer";
import UserReducer from "./users/reducer";
import OrdersReducer from "./orders/reducer";
import QuoteReducer from "./quotes/reducer";
import UsefulFileReducer from "./usefulFiles/reducer";
import DaterReducer from "./Dater/reducer";
import AttributeReducer from "./attributes/reducer";

const rootReducer = combineReducers({
  attributeData: AttributeReducer,
  userData: UserReducer,
  clientData: ClientReducer,
  rubroData: RubroReducer,
  productData: ProductReducer,
  ordersData: OrdersReducer,
  configurationData: ConfigurationReducer,
  balanceData: BalanceReducer,
  quoteData: QuoteReducer,
  usefulFileData: UsefulFileReducer,
  daterData: DaterReducer,
});

export default rootReducer;
