import React, { useEffect, useRef, useState } from "react";

import { Card, Col, Form, Image, Input, message, Row, Select, Spin, Table } from "antd";
import AuthenticatedRoute from "../../core/routes/AuthenticatedRoute";
import { Paths } from "../../config/paths";

import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  ClientDataProps,
  ConfirmDialogTypeEnum,
  ReduxType,
} from "../../shared/types";
import { API_URL } from "../../config/general-config";
import { Endpoints } from "../../config/endpoints";
import { getFoldersAction, getUsefulFileAction } from "../../store/usefulFiles/action";

import { DropdownMenu } from "../../components/dropdownMenu";
import { isAdmin } from "../../core/auth/AuthService";
import * as _ from "lodash";
import { apiDelete, apiPost } from "../../shared/ApiService";
import { ConfirmDialogComponent } from "../../components/confirmDialog";
import {
  DownloadOutlined,
  DownOutlined,
  ExclamationCircleOutlined,
  FolderFilled,
  MehFilled,
  UpOutlined,
} from "@ant-design/icons";
import { CustomModal } from "../../components/modal";
import { Separator } from "../../components/separator";
import Search from "antd/lib/input/Search";

const UsefulFilesPage = () => {

  const navigate = useNavigate();
  const params   = useParams();
  const dispatch = useDispatch();

  const { usefulFileData } = useSelector((state: ReduxType) => {
    return state;
  });

  const [isLoading, setIsLoading] = useState(false);
  const [orderByFilter, setOrderByFilter] = useState('desc');
  const [searchTextFilter, setSearchTextFilter] = useState< undefined | string >(undefined);

  useEffect(() => {
    getData();
  }, [params,orderByFilter]);

  const getData =async () => {
    await getUsefulFiles();
    await getFolders();
  }

  useEffect(() => {
    if(typeof searchTextFilter !== 'undefined'){
      const delayDebounce = setTimeout(() => {
        getUsefulFiles();
        getFolders();
      }, 500)
  
      return () => clearTimeout(delayDebounce)
    }
  }, [searchTextFilter])

  const getUsefulFiles = async () => {
    let url = `${API_URL}/${Endpoints.USEFUL_FILES}`;

    url += `/all?id_folder=${params.folderId}&orderBy=${orderByFilter}&name=${searchTextFilter || ''}`;

    await dispatch(getUsefulFileAction(url, params.folderId));
  };

  const getFolders = async () => {
    await dispatch(getFoldersAction());
  };


  const filterByFolder = async (folderID:string) => {
    navigate(`${Paths.USEFULFILES}/folder/${folderID}`, {
      state: {
        from: `${Paths.USEFULFILES}`
      }
    });
  };

  const setFolderTitle = () => {
    return params.folderId ? <span><span style={{color: 'lightgray'}}> {'>'} </span> {getFolderNameById()}</span> : "";
  };

  const getFolderNameById = () => {
    const folder = usefulFileData.folders?.find(function (folder) {
      return folder._id == params.folderId;
    });

    return folder?.name || "";
  };

  return (
    <AuthenticatedRoute
      path={Paths.USEFULFILES}
      withHeader
      goBack={() => navigate(params.folderId ? Paths.USEFULFILES : Paths.ROOT)}
      headerNavigationName={`Descargas`}
    >
      {!isLoading && (
        <>
          <Separator marginTop={20}>
            <h3 style={{color:'#438eb9'}}>Archivos útiles {setFolderTitle()}</h3>
          </Separator>
          <Separator marginTop={10}>
            <Search style={{paddingLeft:'1px', paddingRight:'5px'}} placeholder="buscar por nombre" onChange={(e)=>{setSearchTextFilter(e.target.value)}} onSearch={(e)=>{setSearchTextFilter(e)}} enterButton />
          </Separator>
          <Separator marginTop={10}>
            <Form.Item
              label="Ordenar por"
              name="orderBy"
              style={{paddingLeft:'1px', paddingRight:'5px'}}
            >
              <Select
                defaultValue="desc"
                style={{ paddingLeft: 10 }}
                onChange={(e) => {setOrderByFilter(e)}}
                options={[
                  { value: 'desc', label: 'Mas recientes.' },
                  { value: 'asc', label: 'Mas antiguos.' },
                  { value: 'az', label: 'A-Z' },
                  { value: 'za', label: 'Z-A' },
                ]}
              />
            </Form.Item>
          </Separator>
          
          
          { !usefulFileData.usefulFiles.loading ?
            <>
                        {
                !usefulFileData.folderId ? 
                <Separator
                marginTop={10}
                colStyle={{display:'flex', flexWrap:'wrap'}}>
                  { usefulFileData.folders?.length > 0 ? 
                      usefulFileData.folders.map(( folder, index:number )=> {
                        return <div
                                key={`folder-${index}`} 
                                className="main-folder-category"
                                onClick={(e) => filterByFolder(folder._id)}>
                                  <div>
                                    <FolderFilled style={{color:'#438EB9',paddingRight:'6px'}}/>
                                    {folder.name}
                                  </div>
                              </div>              
                      }) : null }
                  </Separator> : null
              }
              <Separator
              marginTop={10}
              colStyle={{display:'flex', flexWrap:'wrap'}}>
              {              
                usefulFileData.usefulFiles?.data?.length > 0 ? 
                usefulFileData.usefulFiles?.data?.map(( usefulFile, index:number ) => {
                  return <div
                            key={`usefulFile-${index}`} 
                            className="uf-thumbnail">
                            <div style={{
                              height: '120px',
                              overflow: 'hidden',
                              minWidth: '138px' }} >
                                <div style={{
                                          backgroundColor: "white",
                                          backgroundImage: `url(${API_URL}/public/archivosutiles/${usefulFile.cover}), url(${API_URL}/images/img-placeholder.png)`,
                                          backgroundRepeat: "no-repeat",
                                          backgroundPosition: "center",
                                          height: "100%",
                                          backgroundSize: "cover" }}>
                                </div>
                            </div>
                            <div className="uf-caption">
                                <p className="uf-caption-title">
                                    { usefulFile.nombre }
                                </p>
                                <hr/>
                                <div className="uf-thumbnail-buttons">
                                  <a href={`${API_URL}/public/archivosutiles/${usefulFile.archivo}`} target="_blank">
                                    <DownloadOutlined /> Descargar
                                  </a>
                                </div>
                            </div>
                          </div>
                }) : null
              }
            </Separator> 
            </>
          :     <>
                  <Card key={`Detail-spinner`} className={"card-product-detail"}>
                    <Separator marginTop={10}>
                      <div className={"custom-spinner-style"}>
                        <Spin
                          size="large"
                          tip="Cargando..."
                        ></Spin>
                      </div>
                    </Separator>
                  </Card>
                </>
            }

        </>
      )}
    </AuthenticatedRoute>
  );
};

export default UsefulFilesPage;
