import {
  getCurrentProfileUser,
  getCurrentUser,
  getToken,
} from "../../core/auth/AuthService";
import * as types from "./actionTypes";

const initialState = {
  user: { username: getCurrentUser(), access_token: getToken() },
  userProfile: getCurrentProfileUser(),
  loading: false,
  error: {
    message: "",
  },
};

const UserReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  const actionType = action.type;
  const actionPayload = action.payload;
  switch (actionType) {
    case types.LOGIN_USER:
    case types.LOGIN_PROFILE_USER:
    case types.RESET_USER:
      state = { ...state, loading: true };
      break;

    case types.LOGIN_USER_SUCCESS:
      state = { ...state, user: actionPayload, loading: false };
      break;

    case types.RESET_USER_SUCCESS:
      state = { ...state, loading: false };
      break;

    case types.LOGIN_PROFILE_SUCCESS:
      state = {
        ...state,
        userProfile: actionPayload,
        loading: false,
      };
      break;

    case types.LOGIN_USER_FAIL:
    case types.LOGIN_PROFILE_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loading: false,
      };
      break;
  }

  return state;
};

export default UserReducer;
