import * as types from "./actionTypes";

export const getAttrAction = () => {
  return {
    type: types.GET_ATTR_DATA,
  };
};

export const getAttrFail = (error: any) => {
  return {
    type: types.GET_ATTR_DATA_FAIL,
    payload: error,
  };
};

export const getAttrSuccess = (attrs: any) => {
  return {
    type: types.GET_ATTR_DATA_SUCCESS,
    payload: attrs,
  };
};
