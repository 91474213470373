import * as types from "./actionTypes";

export const getRubroAction = (clientId: number) => {
  return {
    type: types.GET_RUBRO,
    clientId: clientId,
  };
};

export const getRubroFail = (error: any) => {
  return {
    type: types.GET_RUBRO_FAIL,
    payload: error,
  };
};

export const getRubroSuccess = (clients: any) => {
  return {
    type: types.GET_RUBRO_SUCCESS,
    payload: clients,
  };
};
