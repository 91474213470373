import React from "react";
import { Button, Col, Image, Row } from "antd";
import logo from "../../../assets/logo/logo.png";
import dashboardLogo from "../../../assets/logo/dashboardLogo.png";
import backArrow from "../../../assets/icon/backArrow.png";
import menuHamburguer from "../../../assets/icon/menuHamburguer.png";
import { HeaderProps } from "../../../shared/types";
import { useNavigate } from "react-router-dom";
import { Paths } from "../../../config/paths";

const HeaderComponent = (props: HeaderProps) => {
  const navigate = useNavigate();

  const {
    collapsed,
    login,
    dashboard,
    goBack,
    headerNavigationName,
    width,
    withLogo,
    withDashboardLogo,
    toggleCollapsed,
  } = props;

  return (
    <>
      <div
        style={{
          backgroundColor: "#438EB9",
          textAlign: "center",
          height: dashboard ? "12vh" : login ? "35vh" : "10vh",
          minHeight: dashboard ? "12vh" : login ? "35vh" : "10vh",
          position: "relative",
          paddingTop: withLogo ? 110 : 0,
        }}
      >
        {withLogo && (
          <Image style={{ objectFit: "contain" }} src={logo} preview={false} />
        )}

        {withDashboardLogo && (
          <div
            style={{
              paddingTop: 20,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ width: "20%" }}></div>
            <div style={{ width: "60%" }}>
              <Image
                style={{ objectFit: "contain", height: 59 }}
                src={dashboardLogo}
                preview={false}
              />
            </div>
            <div style={{ width: "20%" }}>
              <Button
                onClick={() => toggleCollapsed()}
                style={{
                  backgroundColor: "#438EB9",
                  color: "#FFFFFF",
                  border: "none",
                  marginBottom: 16,
                  fontSize: 25,
                }}
              >
                <Image src={menuHamburguer} preview={false} />
              </Button>
            </div>
          </div>
        )}

        {headerNavigationName && (
          <Row gutter={0} align="middle" style={{ paddingTop: 40 }}>
            <Col span={2}>
              <Row gutter={[0, 5]} align="top" justify="end">
                <Image
                  style={{ objectFit: "contain", cursor: "pointer" }}
                  src={backArrow}
                  preview={false}
                  onClick={() => (goBack ? goBack() : navigate(Paths.ROOT))}
                />
              </Row>
            </Col>
            <Col
              span={width <= 768 ? 14 : 20}
              style={{
                fontStyle: "normal",
                fontWeight: "bold",
                fontSize: 18,
                lineHeight: "16px",
                letterSpacing: 0.4,
                color: "#FFFFFF",
                cursor: "pointer",
                userSelect: "none",
              }}
              onClick={() => (goBack ? goBack() : navigate(Paths.ROOT))}
            >
              {headerNavigationName}
            </Col>
            <Col span={width <= 768 ? 8 : 2}>
              <Button
                onClick={() => toggleCollapsed()}
                style={{
                  backgroundColor: "#438EB9",
                  color: "#FFFFFF",
                  border: "none",
                  fontSize: 25,
                }}
              >
                <Image src={menuHamburguer} preview={false} />
              </Button>
            </Col>
          </Row>
        )}
      </div>
    </>
  );
};

export default HeaderComponent;
