import React from "react";
import { Button, Form, Row } from "antd";
import { InputCheckbox } from "../../../components/inputs";
import { useNavigate } from "react-router-dom";
import { Paths } from "../../../config/paths";

const LoginFooterComponent = (props: any) => {
  const navigate = useNavigate();

  return (
    <Row>
      <div className="loginFooter">
        <div className="loginFooterLeft">
          <Form.Item validateTrigger={["onChange", "onBlur"]} name="remember">
            <InputCheckbox
              enabled
              required={false}
              name={"remember"}
              checkboxLabel={"Recordarme"}
            />
          </Form.Item>
        </div>
        <div className="loginFooterRight" onClick={() => navigate(Paths.RESET)}>
          <span
            style={{
              fontSize: 12,
              color: "#706F6E",
              letterSpacing: 0.4,
              lineHeight: "16px",
              cursor: "pointer",
            }}
          >
            ¿Olvidó su contraseña?
          </span>
        </div>
      </div>
    </Row>
  );
};

export default LoginFooterComponent;
