import { OrdersDataProps, ProductDataProps } from "../../shared/types";
import * as types from "./actionTypes";

export const setProductAction = (product: ProductDataProps) => {
  return {
    type: types.SET_PRODUCT,
    product,
  };
};

export const setProductFail = (error: any) => {
  return {
    type: types.SET_PRODUCT_FAIL,
    payload: error,
  };
};

export const setProductSuccess = (product: any) => {
  return {
    type: types.SET_PRODUCT_SUCCESS,
    payload: product,
  };
};

export const setComplementsAction = (complements: any) => {
  return {
    type: types.SET_COMPLEMENTS,
    complements,
  };
};

export const setComplementsFail = (error: any) => {
  return {
    type: types.SET_COMPLEMENTS_FAIL,
    payload: error,
  };
};

export const setComplementsSuccess = (complements: any) => {
  return {
    type: types.SET_COMPLEMENTS_SUCCESS,
    payload: complements,
  };
};

export const getProductsSelectAction = (rubroId: number) => {
  return {
    type: types.GET_PRODUCTS_SELECT,
    rubroId,
  };
};

export const getProductsSelectFail = (error: any) => {
  return {
    type: types.GET_PRODUCTS_SELECT_FAIL,
    payload: error,
  };
};

export const getProductsSelectSuccess = (products: any) => {
  return {
    type: types.GET_PRODUCTS_SELECT_SUCCESS,
    payload: products,
  };
};

export const getProductsSearchAction = (productUrl: string) => {
  return {
    type: types.GET_PRODUCTS_SEARCH,
    productUrl,
  };
};

export const getProductsSearchFail = (error: any) => {
  return {
    type: types.GET_PRODUCTS_SEARCH_FAIL,
    payload: error,
  };
};

export const getProductsSearchSuccess = (products: any) => {
  return {
    type: types.GET_PRODUCTS_SEARCH_SUCCESS,
    payload: products,
  };
};

export const getProductByIdAction = (productId: number) => {
  return {
    type: types.PRODUCT_SEARCH_BY_ID,
    productId,
  };
};

export const getProductByIdFail = (error: any) => {
  return {
    type: types.PRODUCT_SEARCH_BY_ID_FAIL,
    payload: error,
  };
};

export const getProductByIdSuccess = (product: any) => {
  return {
    type: types.PRODUCT_SEARCH_BY_ID_SUCCESS,
    payload: product,
  };
};

export const getProductsAttrAction = (productId: number) => {
  return {
    type: types.GET_PRODUCTS_ATTR,
    productId,
  };
};

export const getProductsAttrFail = (error: any) => {
  return {
    type: types.GET_PRODUCTS_ATTR_FAIL,
    payload: error,
  };
};

export const getProductsAttrSuccess = (attrs: any) => {
  return {
    type: types.GET_PRODUCTS_ATTR_SUCCESS,
    payload: attrs,
  };
};

export const getProductsChildAction = (
  productId: number,
  description: string,
  appendData: boolean,
  groupid: number
) => {

  return {
    type: types.GET_PRODUCTS_CHILD,
    productId,
    description,
    appendData,
    groupid
  };

};

export const deleteChildProductAction = ( groupid:number ) => {
  return {
    type: types.DELETE_CHILD_PRODUCT_IN_APPEND,
    payload:groupid
  }
}

export const getProductsChildFail = (error: any) => {
  return {
    type: types.GET_PRODUCTS_CHILD_FAIL,
    payload: error,
  };
};

export const getProductsChildAppendFail = (error: any) => {
  return {
    type: types.GET_PRODUCTS_CHILD_APPEND_FAIL,
    payload: error,
  };
};

export const getProductsChildSuccess = (attrs: any) => {
  return {
    type: types.GET_PRODUCTS_CHILD_SUCCESS,
    payload: attrs,
  };
};

export const getProductsChildAppendSuccess = (attrs: any) => {
  return {
    type: types.GET_PRODUCTS_CHILD_APPEND_SUCCESS,
    payload: attrs,
  };
};

export const getProductsComplementAction = (productId: number) => {
  return {
    type: types.GET_PRODUCTS_COMPLEMENTS,
    productId,
  };
};

export const getProductsComplementFail = (error: any) => {
  return {
    type: types.GET_PRODUCTS_COMPLEMENTS_FAIL,
    payload: error,
  };
};

export const getProductsComplementSuccess = (attrs: any) => {
  return {
    type: types.GET_PRODUCTS_COMPLEMENTS_SUCCESS,
    payload: attrs,
  };
};

export const cleanProductStateAction = () => {
  return {
    type: types.CLEAN_PRODUCT_CREATION_STATE,
  };
};

export const cleanProductStateActionFail = (error: any) => {
  return {
    type: types.CLEAN_PRODUCT_CREATION_STATE_FAIL,
    payload: error,
  };
};

export const cleanProductStateActionSuccess = () => {
  return {
    type: types.CLEAN_PRODUCT_CREATION_STATE_SUCCESS,
  };
};

export const addProductToQuoteAction = (productQuote: any) => {
  return {
    type: types.ADD_PRODUCT_TO_QUOTE,
    productQuote,
  };
};

export const addProductToQuoteFail = (error: any) => {
  return {
    type: types.ADD_PRODUCT_TO_QUOTE_FAIL,
    payload: error,
  };
};

export const addProductToQuoteSuccess = (product: any) => {
  return {
    type: types.ADD_PRODUCT_TO_QUOTE_SUCCESS,
    payload: product,
  };
};

export const addProductToQuoteToDeleteAction = (productQuote: any) => {
  return {
    type: types.ADD_PRODUCT_TO_QUOTE_TO_DELETE,
    productQuote,
  };
};

export const addProductToQuoteToDeleteFail = (error: any) => {
  return {
    type: types.ADD_PRODUCT_TO_QUOTE_TO_DELETE_FAIL,
    payload: error,
  };
};

export const addProductToQuoteToDeleteSuccess = (product: any) => {
  return {
    type: types.ADD_PRODUCT_TO_QUOTE_TO_DELETE_SUCCESS,
    payload: product,
  };
};

export const updateQualitativeSelectedValue = (
  productQuote: any
) => {
  return {
    type: types.UPDATE_QUALITATIVE_SELECTED_VALUE, 
    productQuote,
  };
};

export const updateQualitativeSelectedValueFail = (error: any) => {
  return {
    type: types.UPDATE_QUALITATIVE_SELECTED_VALUE_FAIL,
    payload: error,
  };
};

export const updateQualitativeSelectedValueSuccess = (productQuote: any) => {
  return {
    type: types.UPDATE_QUALITATIVE_SELECTED_VALUE_SUCCESS,
    payload: productQuote,
  };
};

export const getProductPriceListAction = () => {
  return {
    type: types.GET_PRODUCT_PRICE_LIST,
  };
};

export const getProductPriceListFail = (error: any) => {
  return {
    type: types.GET_PRODUCT_PRICE_LIST_FAIL,
    payload: error,
  };
};

export const getProductPriceListSuccess = (products: any) => {
  return {
    type: types.GET_PRODUCT_PRICE_LIST_SUCCESS,
    payload: products,
  };
};

export const getProductCompleteDataAction = (productId: number) => {
  return {
    type: types.GET_PRODUCT_COMPLETE_DATA,
    productId,
  };
};

export const getProductCompleteDataFail = (error: any) => {
  return {
    type: types.GET_PRODUCT_COMPLETE_DATA_FAIL,
    payload: error,
  };
};

export const getProductCompleteDataSuccess = (products: any) => {
  return {
    type: types.GET_PRODUCT_COMPLETE_DATA_SUCCESS,
    payload: products,
  };
};

export const setAttrValueNewProductAction = (attr: any) => {
  return {
    type: types.SET_VAL_ATTR_PRODUCT_DATA,
    payload: attr,
  };
};

export const deleteProductOrderAction = (itemId:number, pedidoId:number) => {
  return {
    type: types.DELETE_PRODUCT_FROM_ORDER,
    payload: {itemId, pedidoId}
  }
};

export const deleteProductOrderSuccess = (response:OrdersDataProps) => {
  return {
    type: types.DELETE_PRODUCT_FROM_ORDER_SUCCESS,
    payload: response
  }
};

export const deleteProductOrderFailed = (response:any) => {
  return {
    type: types.DELETE_PRODUCT_FROM_ORDER_FAILED,
    payload:response
  }
};
