import React, { useEffect, useState } from "react";

import { Button, Card, Col, Divider, message, Row, Space, Spin } from "antd";
import AuthenticatedRoute from "../../core/routes/AuthenticatedRoute";
import { Paths } from "../../config/paths";

import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FormTypeEnum, InputNumberType, ReduxType } from "../../shared/types";

import { InputNumber, InputSelect } from "../../components/inputs";
import { FormItemComponent } from "../../components/forms/formItemComponent";
import { Separator } from "../../components/separator";
import * as _ from "lodash";

import { isNumber } from "lodash";
import { ProductDetailMenu } from "../../components/menu";
import { API_URL } from "../../config/general-config";
import { Endpoints } from "../../config/endpoints";
import { apiGet } from "../../shared/ApiService";
import {
  generateDetailDataForDetailPage,
  generateProductDataForDetailPage,
  getBonif,
  getCostAndPriceFromProductQuote,
  getProductFinalCost,
  getProductSalePrice,
  getProfitability,
} from "../../shared/helper";
import {
  addProductToQuoteAction,
  cleanProductStateAction,
  getProductCompleteDataAction,
  getProductPriceListAction,
} from "../../store/products/actions";
import { getAttrAction } from "../../store/attributes/actions";
import NumberFormat from "react-number-format";
import { IProductAttr } from "../../core/models/attributes";
import { getProductQualitativeAttributes } from "../../shared/helpers/attributesHelper";
import { parseToComplementosAdicionales } from "../../shared/helpers/complementsHelper";
import {
  IProductQuoteAditionalComplements,
  IQuoteDetailChildProduct,
  IQuoteDetailQuantitative,
} from "../../core/models/products";
import { hasPermission } from "../../core/auth/AuthService";

const ProductDetailListPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [dataToRender, setDataToRender] = useState<any>([]);
  const [orderFinalCost, setOrderFinalCost] = useState(0);
  const [orderSalePrice, setOrderSalePrice] = useState(0);

  const { state }: any = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { attributeData, clientData, ordersData, quoteData, productData } =
    useSelector((state: ReduxType) => {
      return state;
    });

  useEffect(() => {
    if (!attributeData.attrs) {
      doAttrRequest();
    }
  }, [attributeData.attrs]);

  useEffect(() => {
    if (state && state.canEdit && !productData.productPriceList) {
      doRequestForPrice();
    }
  }, []);

  const doAttrRequest = async () => {
    await dispatch(getAttrAction());
  };

  const doRequestForPrice = async () => {
    await dispatch(getProductPriceListAction());
  };

  const verifyDataForPage = (elements: any) => {
    if (!attributeData.attrs) {
      return;
    }
    if (state && state.canEdit && state.editMode && elements) {
      verifyDataToEdit(elements);
    } else {
      if (elements && clientData.client) {
        verifyQuoteData(elements);
      }
    }
  };

  useEffect(() => {
    verifyDataForPage(ordersData.orderQuote);
  }, [attributeData.attrs, ordersData.orderQuote, clientData.client]);

  useEffect(() => {
    verifyDataForPage(quoteData.quoteToDetail);
  }, [attributeData.attrs, quoteData.quoteToDetail, clientData.client]);

  const preVerifyData = async (quoteElements: any) => {
    if ((quoteElements && quoteElements.length == 0) || !clientData.client) {
      if (state && state.formType == FormTypeEnum.Quote) {
        navigate(Paths.NEWQUOTATION);
      } else {
        navigate(Paths.NEWORDER);
      }
    }
  };

  //---Data verify for edit mode and navigate
  const verifyDataToEdit = async (quoteElements: any) => {
    try {
      await setIsLoading(true);
      preVerifyData(quoteElements);
      const productQuoteToPush: any = [];
      const qualitativeAttributes = getProductQualitativeAttributes(
        quoteElements,
        attributeData,
        state && state.formType
      );

      quoteElements = parseToComplementosAdicionales(quoteElements);

      if (quoteElements && quoteElements.items) {
        await dispatch(cleanProductStateAction());

        for (let i = 0; i < quoteElements.items.length; i++) {
          const element = quoteElements.items[i];
          const productId = element.producto_id;

          const responseProductById = await apiGet({
            url: `${API_URL}/${Endpoints.PRODUCT_SEARCH_BY_ID}/${productId}`,
            unauthorizedCallback: () => {},
          });

          const elementToPush: any = generateProductDataForDetailPage(
            i,
            element,
            state && state.formType,
            qualitativeAttributes.items[i],
            responseProductById,
            await getProductCompleteData(productId, element)
          );

          if (state.formType == FormTypeEnum.Quote) {
            elementToPush.cotizacionId = quoteElements._id;
          }

          if (elementToPush) {
            productQuoteToPush.push(elementToPush);
          }
        }
        await dispatch(addProductToQuoteAction(productQuoteToPush));
        navigate(Paths.PRODUCTDETAIL, {
          state: {
            formType: state && state.formType,
            from:
              state.formType == FormTypeEnum.Order
                ? `${Paths.ORDER}/${state.orderType}`
                : Paths.QUOTATION,
            editMode: state.editMode,
            canEdit: state.canEdit,
            ref: quoteElements.referencia,
          },
        });
      }
    } catch (error: any) {
      message.error(error, 4);
      await setIsLoading(false);
    }
  };

  const getProductCompleteData = async (productId: number, element: any) => {
    const { atributos: attributesSelectedValues } = element;

    const response = await apiGet({
      url: `${API_URL}/${Endpoints.GET_PRODUCT_COMPLETE_DATA}/${productId}`,
      unauthorizedCallback: () => {},
    });

    /* 
      productAttr : atributos CUANTITATIVOS del producto, se busca y agrega el id de referencia de la base de datos.
    */
    return {
      ...response,
      productAttr: response.productAttr.map(
        (productAttribute: IProductAttr) => {
          const selectedValue = attributesSelectedValues.find(
            (selected: any) =>
              productAttribute.atributo_id == selected.atributo_id
          );
          return {
            ...productAttribute,
            value_id: selectedValue && selectedValue._id,
          };
        }
      ),
    };
  };

  //---Quote/Order data to see on page
  const verifyQuoteData = async (quoteElements: any) => {
    try {
      await setIsLoading(true);
      preVerifyData(quoteElements);

      let updatedQuoteProducts = parseToComplementosAdicionales(quoteElements);

      const { totalFinalCost, totalSalePrice } =
        getCostAndPriceFromProductQuote(updatedQuoteProducts.items, clientData);

      updatedQuoteProducts = getProductQualitativeAttributes(
        updatedQuoteProducts,
        attributeData,
        state && state.formType
      );

      setOrderFinalCost(totalFinalCost);
      setOrderSalePrice(totalSalePrice);
      await setDataToRender(updatedQuoteProducts);
      await setIsLoading(false);
    } catch (error: any) {
      await setIsLoading(false);
      message.error(error, 4);
    }
  };

  const cleanAndNavigate = () => {
    setDataToRender([]);
    navigate((state && state.from) || Paths.ROOT);
  };

  return (
    <AuthenticatedRoute
      path={Paths.PRODUCTDETAILLIST}
      withHeader
      goBack={() => cleanAndNavigate()}
      headerNavigationName={
        state && state.formType == FormTypeEnum.Quote
          ? "Detalle de la Cotización"
          : "Detalle del Pedido"
      }
    >
      <>
        {!isLoading ? (
          <>
            {dataToRender &&
              dataToRender.items.map((pq: any, firstIndex: number) => {
                return (
                  <Card
                    key={`Detail-${firstIndex}`}
                    className={"card-product-detail"}
                  >
                    <Separator>
                      <Row align="bottom">
                        <Col flex="auto">
                          <span
                            className={"custom-span-text custom-span-text-font"}
                          >
                            Item:
                          </span>{" "}
                          {firstIndex + 1}
                        </Col>
                        <Col flex="100px" style={{ textAlign: "right" }}>
                          <Space align="center" direction="vertical">
                            <span className="custom-span-text custom-span-text-font">
                              Acciones
                            </span>
                            <ProductDetailMenu
                              onlyView
                              productQuoteElement={pq}
                              productQuoteIndex={firstIndex}
                            />
                          </Space>
                        </Col>
                      </Row>
                    </Separator>
                    <Separator marginTop={2}>
                      <span
                        className={"custom-span-text custom-span-text-font"}
                      >
                        Producto:
                      </span>{" "}
                      {pq.producto.descripcion}
                    </Separator>
                    {generateDetailDataForDetailPage(
                      pq
                    ).detalle?.quantitative?.map(
                      (
                        quantitative: IQuoteDetailQuantitative,
                        InnerIndex: number
                      ) => {
                        return (
                          <Separator
                            key={`attr-quantitative-${InnerIndex}`}
                            marginTop={2}
                          >
                            {quantitative.name}
                            <span
                              className={
                                "custom-span-text custom-span-text-font"
                              }
                            >
                              {" "}
                              {`${parseFloat(
                                `${quantitative.value?.base}`
                              ).toFixed(3)} mts `}{" "}
                              {quantitative.value?.altura &&
                                ` x ${parseFloat(
                                  `${quantitative.value?.altura}`
                                ).toFixed(3)} mts`}
                            </span>
                          </Separator>
                        );
                      }
                    )}

                    {generateDetailDataForDetailPage(
                      pq
                    ).detalle?.childProducts?.map(
                      (
                        childProducts: IQuoteDetailChildProduct,
                        InnerIndex: number
                      ) => {
                        return (
                          <Separator
                            key={`childProducts-${InnerIndex}`}
                            marginTop={2}
                          >
                            {childProducts.name}:
                            <span
                              className={
                                "custom-span-text custom-span-text-font"
                              }
                            >
                              {" "}
                              {childProducts.description}
                            </span>
                          </Separator>
                        );
                      }
                    )}

                    <Separator marginTop={2}>
                      <span
                        className={"custom-span-text custom-span-text-font"}
                      >
                        Cantidad:
                      </span>{" "}
                      {pq.cantidad}
                    </Separator>
                    <Separator>
                      <Row>
                        {hasPermission("READ_FINAL_COST") ? (
                          <Col span={12}>
                            <div
                              className={
                                "custom-span-text custom-span-text-font"
                              }
                            >
                              Costo final
                            </div>
                            <NumberFormat
                              value={getProductFinalCost(
                                pq.precio,
                                clientData,
                                pq.cantidad,
                                true
                              )}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              prefix={"$"}
                            />
                          </Col>
                        ) : null}
                        <Col span={12}>
                          <div
                            className={"custom-span-text custom-span-text-font"}
                          >
                            Precio de venta
                          </div>
                          <NumberFormat
                            value={getProductSalePrice(
                              pq.precio,
                              pq.cantidad,
                              getProfitability(clientData),
                              getBonif(clientData)
                            )}
                            displayType={"text"}
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            prefix={"$"}
                          />
                        </Col>
                      </Row>
                    </Separator>

                    {state.formType == FormTypeEnum.Order &&
                      pq.qualitative &&
                      pq.qualitative.length > 0 && (
                        <>
                          <Separator marginTop={5} marginBottom={5}>
                            <Space>
                              <span
                                className={
                                  "custom-span-text custom-span-text-font"
                                }
                              >
                                Elegir detalle
                              </span>
                            </Space>
                          </Separator>

                          {pq.qualitative.map((pd: any, index: number) => {
                            const descripction =
                              pd.atributo.atributo.descripcion_comercial;
                            const inputSelectName = `${
                              firstIndex + 1
                            }-${descripction}`;

                            const optionList = [
                              {
                                _id: pd.atributo.atributo_item._id.toString(),
                                descripcion_comercial:
                                  pd.atributo.atributo_item.nombre,
                              },
                            ];

                            return (
                              <Separator key={`Detail-Map-${index}`}>
                                <FormItemComponent
                                  inputName={inputSelectName}
                                  rules={[{ name: "required" }]}
                                >
                                  <InputSelect
                                    required
                                    showPlaceholder
                                    enabled={false}
                                    name={inputSelectName}
                                    placeholder={descripction}
                                    initialValue={pd.atributo.valor}
                                    optionList={optionList}
                                  />
                                </FormItemComponent>
                              </Separator>
                            );
                          })}
                        </>
                      )}

                    {pq.complementosAdicionales &&
                      pq.complementosAdicionales.length > 0 && (
                        <>
                          <Separator marginTop={20}>
                            <Space>
                              <span
                                className={"custom-span-text section-title"}
                              >
                                Complementos
                              </span>
                            </Space>
                          </Separator>

                          {state.formType == FormTypeEnum.Order &&
                            pq.complementosAdicionales &&
                            pq.complementosAdicionales.map(
                              (
                                complement: IProductQuoteAditionalComplements,
                                complementIndex: number
                              ) => {
                                const { price, quantity, code, description } =
                                  complement;

                                return (
                                  <div
                                    key={`complementosAdicionales-${complementIndex}`}
                                  >
                                    <Separator marginTop={10}>
                                      <Row>
                                        <Col span={23}>
                                          <span className={"custom-span-text "}>
                                            Código:
                                          </span>{" "}
                                          {code}
                                        </Col>
                                      </Row>
                                    </Separator>
                                    <Separator marginTop={2}>
                                      <span className={"custom-span-text"}>
                                        Productozzz:
                                      </span>{" "}
                                      {description}
                                    </Separator>
                                    <Separator
                                      marginTop={20}
                                      key={`Complement-Map-${complementIndex}`}
                                    >
                                      <FormItemComponent
                                        inputName={"count"}
                                        rules={[{ name: "required" }]}
                                      >
                                        <InputNumber
                                          enabled={false}
                                          required
                                          showPlaceholder
                                          initialValue={quantity}
                                          type={InputNumberType.Number4}
                                          name={"count"}
                                          placeholder={"Cantidad"}
                                          style={{ height: 45 }}
                                        />
                                      </FormItemComponent>
                                    </Separator>
                                    <Separator marginTop={2}>
                                      <span className={"custom-span-text"}>
                                        Costo final:
                                      </span>{" "}
                                      <NumberFormat
                                        value={getProductFinalCost(
                                          price,
                                          clientData,
                                          quantity,
                                          true
                                        )}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        prefix={"$"}
                                      />
                                    </Separator>
                                    <Separator marginTop={2}>
                                      <span className={"custom-span-text"}>
                                        Precio de venta:
                                      </span>{" "}
                                      <NumberFormat
                                        value={getProductSalePrice(
                                          price,
                                          quantity,
                                          getProfitability(clientData),
                                          getBonif(clientData)
                                        )}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        prefix={"$"}
                                      />
                                    </Separator>
                                    {complementIndex !==
                                      pq.complementosAdicionales.length - 1 && (
                                      <Divider
                                        type="horizontal"
                                        className="custom-divider"
                                      />
                                    )}
                                  </div>
                                );
                              }
                            )}
                        </>
                      )}
                  </Card>
                );
              })}

            {dataToRender && dataToRender.items && (
              <>
                {hasPermission("READ_FINAL_COST") ? (
                  <Separator colStyle={{ textAlign: "right" }} marginTop={10}>
                    <Space>
                      <span
                        className={"custom-span-text custom-span-text-font"}
                      >
                        Costo Final
                      </span>
                      <span className={"custom-span-text"}>
                        <NumberFormat
                          value={orderFinalCost.toFixed(2)}
                          displayType={"text"}
                          thousandSeparator={true}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          prefix={"$"}
                        />
                      </span>
                    </Space>
                  </Separator>
                ) : null}

                <Separator colStyle={{ textAlign: "right" }} marginTop={5}>
                  <Space>
                    <span className={"custom-span-text custom-span-text-font"}>
                      Precio de venta
                    </span>
                    <span className={"custom-span-text"}>
                      <NumberFormat
                        value={orderSalePrice.toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        prefix={"$"}
                      />
                    </span>
                  </Space>
                </Separator>
              </>
            )}

            {state && state.canEdit && (
              <Separator marginTop={20}>
                <Button
                  type="primary"
                  className={"custom-button custom-button-blue"}
                  htmlType="submit"
                  disabled={!clientData.client}
                  onClick={() =>
                    verifyDataToEdit(
                      state && state.formType == FormTypeEnum.Quote
                        ? quoteData.quoteToDetail
                        : ordersData.orderQuote
                    )
                  }
                >
                  Editar
                </Button>
              </Separator>
            )}
            <Separator marginTop={20}>
              <Button
                type="primary"
                onClick={() => cleanAndNavigate()}
                className={"custom-button"}
                style={{
                  backgroundColor: "#FFFFFF",
                  color: "#828282",
                }}
              >
                Volver
              </Button>
            </Separator>
          </>
        ) : (
          <>
            <Card key={`Detail-spinner`} className={"card-product-detail"}>
              <Separator marginTop={10}>
                <div className={"custom-spinner-style"}>
                  <Spin
                    size="large"
                    tip={
                      state && state.formType == FormTypeEnum.Order
                        ? `Obteniendo información del Pedido...`
                        : `Obteniendo información de la Cotización...`
                    }
                  ></Spin>
                </div>
              </Separator>
            </Card>
          </>
        )}
      </>
    </AuthenticatedRoute>
  );
};

export default ProductDetailListPage;
