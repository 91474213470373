import { Button, Col, Image, Modal, Row } from "antd";
import _, { isArray } from "lodash";
import React, { useEffect, useState } from "react";
import { CustomModalProps } from "../../shared/types";
import { Separator } from "../separator";

export default function CustomModal(props: CustomModalProps) {
  const {
    name,
    closable,
    isModalVisible,
    handleOk,
    handleCancel,
    showOk,
    showCancel,
    contentMessage,
    image,
    antdIcon,
    showOkText,
    showCancelText,
  } = props;

  const [footer, setFooter] = useState<any[]>([]);

  useEffect(() => {
    const localFooter = [];
    if (showOk) {
      localFooter.push(
        createElement(showOkText ? showOkText : "Aceptar", "submit", handleOk)
      );
    }
    if (showCancel) {
      localFooter.push(
        createElement(
          showCancelText ? showCancelText : "Cancelar",
          "back",
          handleCancel
        )
      );
    }
    setFooter(localFooter);
  }, [props]);

  const createElement = (name: string, key: string, handle: any) => {
    return (
      <Button
        key={key}
        onClick={handle}
        type={name == "Aceptar" ? "primary" : "default"}
        style={name == "Aceptar" ? { background: "#438EB9" } : {}}
      >
        {name}
      </Button>
    );
  };

  return (
    <Modal
      closable={closable && closable}
      title={name}
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={footer.length > 0 && footer}
    >
      <div
        style={{
          textAlign: "center",
          position: "relative",
        }}
      >
        {(image || antdIcon) && (
          <div
            style={{
              textAlign: "center",
              position: "relative",
            }}
          >
            <Row gutter={0} align="middle" style={{ paddingTop: 20 }}>
              <Col span={24}>
                {image ? (
                  <Image
                    style={{ objectFit: "contain" }}
                    src={image}
                    preview={false}
                  />
                ) : (
                  antdIcon
                )}
              </Col>
            </Row>
          </div>
        )}
        <Row gutter={0} align="middle" style={{ paddingTop: 20 }}>
          <Col span={24}>
            <div
              style={{
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: 14,
                lineHeight: "20px",
                letterSpacing: 0.25,
              }}
            >
              { contentMessage && isArray(contentMessage)
                ? contentMessage.map(( message: string, index: number ) => 
                  { 
                    return <div key={`message-${index}`}>{ message } </div>; 
                  })
                : contentMessage 
              }
            </div>
          </Col>
        </Row>
      </div>
    </Modal>
  );
}
