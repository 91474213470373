import { Endpoints } from "../../config/endpoints";
import {
  API_URL,
  LOCAL_STORAGE_JWT,
  LOCAL_STORAGE_PROFILE,
} from "../../config/general-config";
import { apiGet, apiPost } from "../../shared/ApiService";
import { asyncLocalStorage, findIndex } from "../../shared/helper";
import { LoggedInUser, LoginRequest, LoginResponse } from "./Type";

import * as _ from "lodash";

const loginWithUsernameAndPassword = async (
  username: string,
  password: string
) => {
  const urlLogin = `${API_URL}/${Endpoints.LOGIN}`;

  const bodyLogin: LoginRequest = {
    username,
    password,
    grant_type: "password",
    client_id: "testclient",
    client_secret: "testpass",
  };

  const responseLogin: LoginResponse = await apiPost({
    url: urlLogin,
    body: bodyLogin,
    unauthorizedCallback: () => {},
  });

  const { access_token } = responseLogin;

  if (!access_token) {
    throw new Error("Se ha producido un error. Verifique sus credenciales");
  }

  await saveUserProfileToLocalStorage({ username: username });
  await saveTokenToLocalStorage(access_token);

  return {
    username,
    access_token,
  };
};

const getProfileUser = async () => {
  const urlLogin = `${API_URL}/${Endpoints.PROFILE}`;

  const response = await apiGet({
    url: urlLogin,
    unauthorizedCallback: () => {
      unauthorizedCallback();
    },
  });
  if (!_.isEmpty(response)) {
    await saveUserProfileToLocalStorage(response);
    return response;
  }
};

const logout = () => {
  localStorage.removeItem(LOCAL_STORAGE_JWT);
  localStorage.removeItem(LOCAL_STORAGE_PROFILE);
};

const getCurrentUser = () => {
  const jsonProfile = localStorage.getItem(LOCAL_STORAGE_PROFILE);
  if (!jsonProfile) {
    return undefined;
  }
  const user: LoggedInUser = JSON.parse(jsonProfile) as LoggedInUser;
  return user.username;
};

const getCurrentProfileUser = () => {
  return localStorage.getItem(LOCAL_STORAGE_PROFILE) || undefined;
};

const getToken = () => {
  return localStorage.getItem(LOCAL_STORAGE_JWT) || undefined;
};

const isLoggedIn = () => {
  const token = localStorage.getItem(LOCAL_STORAGE_JWT);
  const user = localStorage.getItem(LOCAL_STORAGE_PROFILE);

  return !!token && !!user;
};

const hasPermission = (permission:string) => {
  const localStorageItem:string|null = localStorage.getItem(LOCAL_STORAGE_PROFILE);
  let found = false;
  if (typeof localStorageItem === 'string') {
    const userProfile:any = JSON.parse(localStorageItem);
    userProfile.roles.every(function(rol:any) {
      if (rol.permisos) {
          rol.permisos.every(function(permiso:any) {
              if (permiso.nombre === permission) {
                  found = true;
              }
              return !found;
          });
      }
      return !found;
  });
  }
  return found;
}

const isAdmin = () => {
  const userProfile = localStorage.getItem(LOCAL_STORAGE_PROFILE);
  if (userProfile) {
    const userProfileObj: LoggedInUser = JSON.parse(userProfile);
    if (userProfileObj && userProfileObj.roles) {
      const index = findIndex(userProfileObj.roles, "nombre", "ADMINISTRADOR");
      return index !== -1;
    }
  }
  return false;
};

const isClient = () => {
  const userProfile = localStorage.getItem(LOCAL_STORAGE_PROFILE);
  if (userProfile) {
    const userProfileObj: LoggedInUser = JSON.parse(userProfile);
    if (userProfileObj && userProfileObj.roles) {
      return userProfileObj.roles.some((role:any) => {
        return role.nombre === "CLIENTE_VENDEDOR" || role.nombre === "CLIENTE"
      });      
    }
    return false;
  }
  return false;
};

const saveUserProfileToLocalStorage = async (profile: any) => {
  await asyncLocalStorage.setItem(
    LOCAL_STORAGE_PROFILE,
    JSON.stringify(profile)
  );
};

const saveTokenToLocalStorage = async (token: string) => {
  await asyncLocalStorage.setItem(LOCAL_STORAGE_JWT, token);
};

const unauthorizedCallback = function () {
  logout();
  window.location.reload();
};

export {
  getToken,
  getCurrentUser,
  getCurrentProfileUser,
  saveUserProfileToLocalStorage,
  isLoggedIn,
  isAdmin,
  isClient,
  logout,
  getProfileUser,
  loginWithUsernameAndPassword,
  unauthorizedCallback,
  hasPermission
};
