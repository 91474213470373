import React from "react";
import { Provider } from "react-redux";
import { RouterConfig } from "./core/routes/RouterConfig";
import store from "./store";
import "./styles/index.css";
import "antd/dist/antd.css";
import Cookies from 'js-cookie';

function App() {

  (() => {
    console.log("caches", caches);
    caches.keys().then((names) => {
        names.forEach((name) => {
            caches.delete(name);
        });
    });
    console.log("Complete Cache Cleared");
})();
 
(() => {
  console.log('Cookies libreria:', Cookies.get())
  Object.keys(Cookies.get()).forEach(function(cookieName) {
    const neededAttributes = {
      path: '/'
    };
    Cookies.remove(cookieName, neededAttributes);
  });
})();

(()=>{
  const cookies = document.cookie.split(';');
  console.log("Cookies js:", cookies);
    // Itera sobre cada cookie y establece su fecha de expiración al pasado
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf('=');
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
})();

  return (
    <React.Suspense fallback="Cargando">
      <Provider store={store}>
        <RouterConfig />
      </Provider>
    </React.Suspense>
  );
}

export default App;
