import React from "react";
import { Result, Button } from "antd";
import { HeaderComponent } from "../../components/base";
import { useNavigate } from "react-router-dom";
import { Paths } from "../../config/paths";
import AuthenticatedRoute from "../../core/routes/AuthenticatedRoute";

const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <AuthenticatedRoute
      path={Paths.PAGENOTFOUNT}
      withHeader
      goBack={() => navigate(Paths.ROOT)}
      headerNavigationName={`Pagina no encontrada`}
    >
      <Result
        status="404"
        title="404"
        subTitle="Lo sentimos, la página que visitaste no existe."
        extra={
          <Button type="primary" onClick={() => navigate(Paths.ROOT)}>
            Volver al inicio
          </Button>
        }
      />
    </AuthenticatedRoute>
  );
};

export default NotFoundPage;
