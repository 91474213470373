import { Select } from "antd";
import _, { isArray } from "lodash";
import React, { useEffect, useState } from "react";
import {
  FilterProps,
  KeyValuePair,
  OptionListProps,
} from "../../../shared/types";
import { InputOptionDisplay } from "../inputOptionToDisplay";

const { Option } = Select;

export default function InputSelect(props: FilterProps) {
  const [options, setOptions] = useState<Array<KeyValuePair>>([]);
  const [required, setRequired] = useState<boolean>(false);
  const [className, setClassName] = useState<string>(
    `form__input-select ${props.classname && props.classname}`
  );
  const [value, setValue] = useState<any>(undefined);
  const [valueArrayBackup, setValueArrayBackup] = useState<any>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    /*
      Chrome does not handle the autocomplete attribute as it should.
      We have to set any other value that is not 'off' or 'false', that will broke it's detection. 
    */
    document.querySelectorAll(".ant-select-selector input").forEach((e) => {
      e.setAttribute("autocomplete", "chrome-off");
      e.setAttribute("type", "search");
    });
  }, []);

  const onBlur = () => {
    if (props.required && props.enabled) {
      if (
        value === null ||
        value === undefined ||
        (typeof value === "object" && value.length === 0)
      ) {
        setClassName("form__input-select form__label--error");
        setRequired(true);
      } else {
        setClassName("form__input-select");
        setRequired(false);
      }
    }
  };

  useEffect(() => {
    if (!props.required || !props.enabled) {
      setClassName("form__input-select");
      setRequired(false);
    }
  }, [props.enabled, props.required]);

  useEffect(() => {
    let initial;
    if (isArray(props.initialValue)) {
      initial = props.initialValue[0];
      setValueArrayBackup(initial);
    } else {
      initial = props.initialValue;
    }

    if (initial) {
      props.onChange && props.onChange(initial);
      setValue(initial && initial._id ? initial._id : initial);
    }
  }, [props.initialValue]);

  useEffect(()=>{
    const { optionList } = props;
    const options = optionList || [];
    if(value){
      
      const isPresent = options.some(( option:any )=>{
        return option._id == value;
      });

      if(!isPresent)
      {
        setValue(undefined);
        props.onChange && props.onChange(undefined);
      }
    }
  },[props.optionList]);

  const filterBySearch: any = (input: any, option: any) => {
    return (
      option.children.props.value.toLowerCase().indexOf(input.toLowerCase()) >=
      0
    );
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Select
        size={"large"}
        disabled={!props.enabled}
        className={className}
        showSearch
        placeholder={props.showPlaceholder && props.placeholder}
        optionFilterProp="children"
        value={value}
        onClear={() => {
          if (props.required && props.enabled) {
            setClassName("form__input-select form__label--error");
            setRequired(true);
          }
        }}
        filterOption={filterBySearch}
        onChange={(value: any) => {
          setValue(value);
          if (valueArrayBackup && valueArrayBackup._id) {
            valueArrayBackup._id = value;
            props.onChange &&
              props.onChange &&
              props.onChange(valueArrayBackup);
            setValueArrayBackup(valueArrayBackup);
          } else {
            props.onChange && props.onChange && props.onChange(value);
          }
        }}
        onBlur={!props.onBlur ? onBlur : undefined}
      >
        {options.length > 0 &&
          options.map((elem: KeyValuePair) => (
            <Option value={elem.id} key={elem.id + elem.value}>
              <InputOptionDisplay value={elem.value} />
            </Option>
          ))}
        {props.optionList &&
          props.optionList.length > 0 &&
          props.optionList.map((elem: OptionListProps) => {
            const val = elem.descripcion_comercial || elem.descripcion;
            if (val) {
              return (
                <Option value={elem._id} key={props.name + elem._id}>
                  <InputOptionDisplay value={val} />
                </Option>
              );
            }
          })}
      </Select>
    </div>
  );
}
