import { takeLatest, put, call } from "redux-saga/effects";

import { GET_ATTR_DATA } from "./actionTypes";

import { getAttrFail, getAttrSuccess } from "./actions";

import { apiGet } from "../../shared/ApiService";
import { Endpoints } from "../../config/endpoints";
import { API_URL } from "../../config/general-config";

function* onGetAttrData() {
  try {
    const response: any[] = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_URL}/${Endpoints.ATTR_DATA}`,
          unauthorizedCallback: () => {},
        })
      );
    });
    yield put(getAttrSuccess(response));
  } catch (error: any) {
    yield put(getAttrFail(error.response));
  }
}

function* AttributeSaga() {
  yield takeLatest(GET_ATTR_DATA, onGetAttrData);
}

export default AttributeSaga;
