import { message } from "antd";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Spinner } from "../../components/spinner";
import { Paths } from "../../config/paths";
import { logout, unauthorizedCallback } from "../../core/auth/AuthService";
import AuthenticatedRoute from "../../core/routes/AuthenticatedRoute";

const LogoutPage = () => {
  useEffect(() => {
    try {
      unauthorizedCallback();
    } catch (error: any) {
      message.error(error.message);
    }
  }, []);

  return (
    <AuthenticatedRoute
      dashboard
      withHeader
      withDashboardLogo
      path={Paths.LOGOUT}
    >
      <Spinner />
    </AuthenticatedRoute>
  );
};

export default LogoutPage;
