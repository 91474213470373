import React, { useState } from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { Paths } from "../../../config/paths";

const ResetFooterComponent = (props: any) => {
  const navigate = useNavigate();

  const { handleSubmit, isSubmitting } = props;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        marginTop: 50,
      }}
    >
      <div style={{ width: 150, height: 45 }}>
        <Button
          className={"custom-button"}
          onClick={() => navigate(Paths.LOGIN)}
          style={{
            backgroundColor: "#FFFFFF",
            color: "#438EB9",
            border: "1px solid #438EB9",
            height: "100%",
          }}
        >
          Volver
        </Button>
      </div>
      <div style={{ width: 150, height: 45 }}>
        <Button
          key="submit"
          onClick={() => handleSubmit()}
          className={`custom-button ${
            isSubmitting ? "custom-button-disabled" : "custom-button-blue"
          }`}
          style={{
            color: "#FFFFFF",
            border: "1px solid #438EB9",
            height: "100%",
          }}
          disabled={isSubmitting}
        >
          Recuperar
        </Button>
      </div>
    </div>
  );
};

export default ResetFooterComponent;
