import { InputNumber as InputNumberAntd } from "antd";
import React, { FocusEventHandler, useEffect, useState } from "react";
import {
  InputFloatLabelProps, InputNumberTypePrecisionProps
} from "../../../shared/types";
import { FloatLabel } from "../floatLabel";
import { InputTypePrecision } from "./InputNumberConst";

export default function InputNumber(props: InputFloatLabelProps) {
  const [className, setClassName] = useState<string>(
    props.alignRight
      ? `form__input custom-ant-input-number-input ${
          props.classname && props.classname
        }`
      : `form__input ${props.classname && props.classname}`
  );
  const [inputState, setInputState] = useState<number | undefined>(undefined);
  const [localTypeAndPrecision, setLocalTypeAndPrecision] =
    useState<InputNumberTypePrecisionProps>({ type: "", precision: undefined });

  useEffect(() => {
    if (props.type) {
      const typeAndPrecision = InputTypePrecision[props.type];
      if (typeAndPrecision) {
        setLocalTypeAndPrecision(typeAndPrecision);
      }
    }
  }, [props.type]);

  useEffect(() => {
    /*
      Chrome does not handle the autocomplete attribute as it should.
      We have to set any other value that is not 'off' or 'false', that will broke it's detection.
    */
    document
      .querySelectorAll(".ant-input-number-input-wrap input")
      .forEach((e) => {
        e.setAttribute("autocomplete", "chrome-off");
        e.setAttribute("type", "search");
      });
  }, []);

  useEffect(() => {
    if (props.initialValue) {
      props.onChange && props.onChange(getNumberValue(props.initialValue));
      setInputState(props.initialValue);
    }
  }, [props.initialValue]);

  const finalFormatter = (preValue: any) => {
    if (localTypeAndPrecision.type === "percentage") {
      return `${preValue} %`;
    }
    return preValue;
  };

  const finalParser = (preValue: any) => {
    if (localTypeAndPrecision.type === "percentage") {
      return preValue.replace(" %", "");
    }
    return preValue;
  };

  useEffect(() => {
    let classname = props.alignRight
      ? "form__input custom-ant-input-number-inpuprops.classname && props.classnamet "
      : "form__input ";
    if (props.classname) {
      classname += props.classname;
    }
    if (!props.required || !props.enabled) {
      setClassName(classname);
    }
  }, [props.required, props.enabled]);

  const onBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    const value = e.target.value;
    if (props.required && props.enabled) {
      const classname = props.alignRight
        ? "form__input custom-ant-input-number-inpuprops.classname && props.classnamet"
        : "form__input";
      if (value === "" || value === null || value === undefined) {
        setClassName(classname + " form-input__error");
      } else {
        setClassName(classname);
      }
    }
  };

  const getNumberValue = (inputValue: any) => {
    /* en caso de estar definido el value, se retorna el mismo.
    */
    if (inputValue !== undefined && inputValue !== null && inputValue !== "") 
    {
      return +inputValue;
    } 
    
    /* si el value no esta definido, pero la propiedad "min" esta establecida,
    se retorna el valor del "min" que garantiza que un valor minimo. caracteristica
    necesaria en inputs de "cantidad" de productos, donde siempre debe haber al menos 1 producto.
    
    si el value no esta definido, y no hay "min" simplemente retorona el valor entrante.*/
    return props.min || inputValue;
  };

  const onLocalChange = (e: any) => {
    if(props.min && !e)
    {      
      setInputState(props.min);
    }else{
      setInputState(e);
    }

    props.onChange && props.onChange(getNumberValue(e));
  };

  const InputElement = <InputNumberAntd
                        size={"large"}
                        type={"number"}
                        decimalSeparator={localTypeAndPrecision.precision ? "." : undefined}
                        precision={
                          localTypeAndPrecision.type
                            ? localTypeAndPrecision.precision
                            : undefined
                        }
                        step={
                          localTypeAndPrecision.step ? localTypeAndPrecision.step : undefined
                        }
                        disabled={!props.enabled}
                        value={inputState}
                        placeholder={props.showPlaceholder ? props.placeholder : ""}
                        className={
                          props.enabled
                            ? className + " ant-input-number-not-enabled"
                            : className
                        }
                        formatter={finalFormatter}
                        min={props.min}
                        parser={finalParser}
                        onChange={(e: any) => {
                          onLocalChange(e);
                        }}
                        onBlur={!props.onBlur ? onBlur : undefined}
                        style={props.style && props.style}
                        stringMode
                      />

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {props.floatLabel ? 
        <FloatLabel label={props.floatLabel} value={inputState}>
          {InputElement}
        </FloatLabel>
        :
        InputElement
      }
    </div>
  );
}
