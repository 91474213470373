import React, { useEffect, useRef, useState } from "react";

import { Col, Image, message, Modal, Row, Table } from "antd";
import AuthenticatedRoute from "../../core/routes/AuthenticatedRoute";
import { Paths } from "../../config/paths";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ReduxType } from "../../shared/types";
import { API_URL } from "../../config/general-config";
import { Endpoints } from "../../config/endpoints";
import { Pagination } from "antd";

import Edit from "../../assets/icon/productDetail/edit.png";
import Delete from "../../assets/icon/productDetail/delete.png";

import { DropdownMenu } from "../../components/dropdownMenu";
import { isAdmin } from "../../core/auth/AuthService";
import * as _ from "lodash";
import { apiDelete, apiPost } from "../../shared/ApiService";
import {
  DownOutlined,
  ExclamationCircleOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { getDaterAction } from "../../store/Dater/action";
import { DaterForm } from "../../components/forms/daterForm";
import { CustomModal } from "../../components/modal";
import moment from "moment";

const DaterPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [daterToDelete, setDaterToDelete] = useState<any>();

  const [selectedElement, setSelectedElement] = useState();
  const [verifyAdmin, setVerifyAdmin] = useState<boolean | undefined>(
    undefined
  );
  const [paginationHeight, setPaginationHeight] = useState(0);
  const paginationRef = useRef<any>(null);

  const [currentPage, setCurrentPage] = useState<number>(1);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { daterData } = useSelector((state: ReduxType) => {
    return state;
  });

  useEffect(() => {
    if (paginationRef && paginationRef.current)
      setPaginationHeight(paginationRef.current.clientHeight);
  }, [paginationRef]);

  useEffect(() => {
    if (currentPage && verifyAdmin !== undefined) {
      doRequest(currentPage);
    }
  }, [currentPage, verifyAdmin]);

  useEffect(() => {
    if (verifyAdmin == undefined) {
      setVerifyAdmin(isAdmin());
    }
  }, [verifyAdmin]);

  const doRequest = async (page: number) => {
    let url = `${API_URL}/${Endpoints.DATER}`;
    if (page) {
      url += `?page=${page}&pageSize=10`;
    }
    await dispatch(getDaterAction(url));
  };

  const mainGridColummns = () => {
    const columns = [
      {
        title: "Productos de los Rubros",
        dataIndex: "rubro",
        render(text: any, record: any) {
          return {
            props: {
              style: { color: "#706F6E" },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: "Asignados a partir del:",
        dataIndex: "fecha",
        render(text: any, record: any) {
          return {
            props: {
              style: { color: "#706F6E" },
            },
            children: <div>{moment(text).format("DD/MM/YYYY")}</div>,
          };
        },
      },
    ];
    if (verifyAdmin != undefined && verifyAdmin) {
      columns.push({
        title: "Acciones",
        dataIndex: "acciones",
        render: (value: any, record: any, index: any) => {
          const menu = [
            {
              name: "Editar",
              icon: (
                <Image
                  className={"menu-custom-icon"}
                  src={Edit}
                  preview={false}
                />
              ),
              action: () => handleEditAction(record),
            },
            {
              name: "Eliminar",
              icon: (
                <Image
                  className={"menu-custom-icon"}
                  src={Delete}
                  preview={false}
                />
              ),
              action: () => handleDeleteAction(record),
            },
            {
              name: "Subir Elemento",
              icon: <UpOutlined style={{ fontSize: 15 }} />,
              action: () => handleElementAction(record, "UP"),
            },
            {
              name: "Bajar Elemento",
              icon: <DownOutlined style={{ fontSize: 15 }} />,
              action: () => handleElementAction(record, "DOWN"),
            },
          ];
          return (
            <div style={{ textAlign: "center", fontSize: 15 }}>
              <DropdownMenu menu={menu} />
            </div>
          );
        },
      } as any);
    }
    return columns;
  };

  const handleElementAction = async (dater: any, direction: string) => {
    try {
      const url = `${API_URL}/${
        direction == "UP" ? Endpoints.DATER_GO_UP : Endpoints.DATER_GO_DOWN
      }`;
      await apiPost({
        url,
        body: { ...dater },
        unauthorizedCallback: () => {},
      });
      doRequest(currentPage);
    } catch (error: any) {
      message.error(error);
    }
  };

  const handleEditAction = async (dater: any) => {
    await setSelectedElement(dater);
    await setIsModalVisible(true);
  };

  const handleDeleteAction = async (dater: any) => {
    await cleanOrSetStates(dater, true);
  };

  const deleteDocument = async () => {
    try {
      if (daterToDelete) {
        const url = `${API_URL}/${Endpoints.DATER}/${daterToDelete._id}`;
        await apiDelete({
          url,
          unauthorizedCallback: () => {},
        });
        message.info("El elemento se elimino de forma correcta.");
        await cleanOrSetStates(undefined, false);
        await doRequest(currentPage);
      }
    } catch (error: any) {
      message.error(error);
    }
  };

  const cleanOrSetStates = async (dater: any, modalVisible: boolean) => {
    await setDaterToDelete(dater);
    await setIsDeleteModalVisible(modalVisible);
  };

  const dataSourceTable: any[] | undefined =
    daterData.dater &&
    daterData.dater.data &&
    _.sortBy(daterData.dater.data, [
      function (o) {
        return o.orden;
      },
    ]).map((col: any, index: number) => {
      return { key: index, ...col };
    });

  return (
    <AuthenticatedRoute
      path={Paths.DATER}
      withHeader
      goBack={() => navigate(Paths.ROOT)}
      headerNavigationName={`Fechador`}
    >
      {!isLoading && (
        <>
          <CustomModal
            closable={false}
            showOk={true}
            isModalVisible={isDeleteModalVisible}
            handleOk={() => deleteDocument()}
            handleCancel={() => cleanOrSetStates(undefined, false)}
            antdIcon={
              <>
                <ExclamationCircleOutlined
                  style={{ color: "#F59422", height: 45, width: 45 }}
                  className="custom-modal_icon"
                />
              </>
            }
            contentMessage={
              "¿Desea eliminar este elemento de forma permanente?"
            }
          />

          {
            (verifyAdmin != undefined && verifyAdmin) && 
            <DaterForm updateList={() => doRequest(currentPage)} />
          }


          <Modal
            onCancel={() => {
              setSelectedElement(undefined);
              setIsModalVisible(false);
            }}
            cancelText="Cancelar"
            okText="Guardar"
            visible={!!isModalVisible}
            closable={false}
            footer={null}
          >
            <div style={{ textAlign: "center", margin: "14px 0 28px 0" }}>
              <span
                style={{
                  fontWeight: 700,
                  fontSize: 14,
                  color: "rgba(0, 0, 0, 0.6)",
                }}
              >
                Editar Fechador
              </span>
            </div>
            <DaterForm
              isModal
              selectedElement={selectedElement}
              onHandleCancel={() => {
                setSelectedElement(undefined);
                setIsModalVisible(false);
              }}
              updateList={() => {
                setSelectedElement(undefined);
                setIsModalVisible(false);
                doRequest(currentPage);
              }}
            />
          </Modal>

          <Row
            align="middle"
            justify="space-between"
            style={{ marginTop: 20, marginBottom: paginationHeight }}
          >
            <Col span={24}>
              <Table
                id={"custom-table"}
                className={"align-center-table-header-action"}
                columns={mainGridColummns()}
                dataSource={dataSourceTable}
                pagination={false}
              />
            </Col>
          </Row>

          {daterData.dater && (
            <div
              style={{
                padding: 10,
                backgroundColor: "#f0f0f0",
                position: "fixed",
                bottom: 0,
                width: "100%",
              }}
              ref={paginationRef}
            >
              <Pagination
                style={{ float: "right" }}
                current={currentPage}
                onChange={(page) => setCurrentPage(page)}
                total={daterData.dater.total}
                showSizeChanger={false}
              />
            </div>
          )}
        </>
      )}
    </AuthenticatedRoute>
  );
};

export default DaterPage;
