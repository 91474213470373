import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";

const Separator = (props: any) => {
  return (
    <Row
      justify="center"
      align="middle"
      style={{ marginTop: props.marginTop, marginBottom: props.marginBottom }}
    >
      <Col style={{...props.colStyle}} xs={20} xl={10}>
        {props.children}
      </Col>
    </Row>
  );
};

export default Separator;
