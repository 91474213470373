export const Endpoints = {
  ATTR_DATA: "services/articulos/all",
  BALANCE_RESUME: "/solicita-resumen",
  CANCEL_ORDER: "services/pedidos/anular",
  CANCEL_QUOTE: "services/cotizaciones/anular/",
  CLIENTS: "services/clientes",
  CLIENTS_PARAMETERS: "services/parametros",
  CLIENTS_WITH_PARAMETERS: "api/v2/services/clientes",
  CONFIGURATION_BY_USER: "services/parametros/byUser/",
  CONFIGURATION_PARAMS: "services/parametros",
  DATER: "services/fechadores",
  DATER_GO_UP: "services/fechadores/subirElemento",
  DATER_GO_DOWN: "services/fechadores/bajarElemento",
  DOWNLOAD_USEFUL_FILE: "public/archivosutiles",
  GET_LOGO_UPLOADS: "uploads/",
  GET_PDF_CLIENTE: "services/cotizaciones/pdf-cliente/",
  GET_PDF_CLIENTE_WITH_ATTRIBUTES: "services/cotizaciones/pdf-cliente-with-attributes/",
  GET_PDF_EXPEDICION: "services/pedidos/pdf-distribuidor/expedicion/",
  GET_PDF_FACTURACION: "services/pedidos/pdf-distribuidor/",
  GET_PDF_USUARIO: "services/cotizaciones/pdf-distribuidor-cliente/",
  GET_PDF_USUARIO_WITH_ATTRIBUTES: "services/cotizaciones/pdf-distribuidor-cliente-with-attributes/",
  GET_PRODUCT_COMPLETE_DATA: "api/v2/services/products",
  LOGIN: "services/auth/login",
  ORDERS_DRAFT_SEARCH: "services/pedidos?is_borrador=true",
  ORDERS_SEARCH_FROM_LIST: "services/pedidos",
  ORDERS_SEARCH: "services/pedidos?is_borrador=false",
  PRICES_LIST: "services/listas-de-precios/vigente",
  PRODUCT_ATTR: "services/productos/atributosItems",
  PRODUCT_CHILD: "services/productos/productosHijos",
  PRODUCT_COMPLEMENTS: "services/productos/complementosNecesarios",
  PRODUCT_SEARCH: "services/productos/findBy",
  PRODUCT_SEARCH_BY_ID: "services/productos",
  PRODUCT_SELECT: "services/productos/byGrupo",
  PROFILE: "services/security/profile",
  QUOTE_SEARCH: "services/cotizaciones",
  RUBRO: "services/grupos/cotizables",
  RESET: "services/users/password/reset",
  RECOVER: "services/users/password/recover",
  SAVE_GET_QUOTE: "services/cotizaciones",
  SAVE_ORDER: "services/pedidos",
  UPLOAD_LOGO: "upload/",
  UPDATE_ORDER: "services/pedidos/update/",
  UPDATE_QUOTE: "services/cotizaciones/update/",
  USER: "services/clientes/usuario",
  USEFUL_FILES: "services/archivos-utiles",
  FOLDERS: "services/folder",
  GET_PRODUCT_PRICE_BASE: "services/calculadora",
  DELETE_PRODUCT_FROM_ORDER: "services/pedidos/eliminarItem"
};
