import {
  Col,
  Form,
  Row,
  FormInstance,
  DatePicker,
  Button,
  message,
} from "antd";
import React from "react";
import { DaterFormProps } from "../../../shared/types";
import { InputText } from "../../inputs";
import { FormItemComponent } from "../formItemComponent";
import moment from "moment";
import { API_URL } from "../../../config/general-config";
import { Endpoints } from "../../../config/endpoints";
import { apiPost, apiPut } from "../../../shared/ApiService";

const DaterForm = (props: DaterFormProps) => {
  const [form] = Form.useForm();
  const formRef = React.createRef<FormInstance>();

  const { onHandleCancel, selectedElement, updateList } = props;
  const dateFormat = "YYYY-MM-DD";

  const handleSubmit = async (values: any) => {
    try {
      if (values.rubro && values.fecha) {
        const formatDate = moment(values.fecha, "YYYY-MM-DD").format(
          dateFormat
        );
        const body = {
          rubro: values.rubro,
          fecha: formatDate,
        };
        const url = `${API_URL}/${Endpoints.DATER}${
          selectedElement && selectedElement._id
            ? `/${selectedElement._id}`
            : ""
        }`;
        if (selectedElement && selectedElement._id) {
          await apiPut({
            url,
            body: body,
            unauthorizedCallback: () => {},
          });
        } else {
          await apiPost({
            url,
            body: body,
            unauthorizedCallback: () => {},
          });
        }
        form.resetFields();
        updateList();
      }
    } catch (error: any) {
      message.error(error);
    }
  };

  return (
    <Row justify="center" align="middle" style={{ marginTop: 20 }}>
      <Col xs={selectedElement ? 24 : 20} xl={selectedElement ? 14 : 10}>
        <Form
          name="basic"
          layout="vertical"
          ref={formRef}
          form={form}
          onFinish={handleSubmit}
          fields={[
            {
              name: ["fecha"],
              value:
                selectedElement &&
                selectedElement.fecha &&
                moment(selectedElement.fecha, dateFormat),
            },
            {
              name: ["fecha2"],
              value:
                selectedElement &&
                selectedElement.fecha &&
                moment(selectedElement.fecha, dateFormat),
            },
          ]}
        >
          <FormItemComponent inputName="rubro" rules={[]}>
            <InputText
              enabled
              initialValue={selectedElement && selectedElement.rubro}
              name="rubro"
              placeholder="Rubro"
              showPlaceholder
              required
              type="text"
            />
          </FormItemComponent>

          {selectedElement && selectedElement.fecha ? (
            <>
              <div
                style={{ display: "flex", flexDirection: "column" }}
                className={"inputFormBorder"}
              >
                <FormItemComponent inputName="fecha" rules={[]}>
                  <DatePicker
                    name="fecha"
                    placeholder="Fecha"
                    style={{ height: 45, width: "100%" }}
                  />
                </FormItemComponent>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >
                <div style={{ width: 105, height: 45 }}>
                  <Button
                    className={`custom-button `}
                    type="primary"
                    htmlType="submit"
                    style={{
                      borderRadius: "4px",
                      backgroundColor: "#438EB9",
                      height: "100%",
                    }}
                  >
                    Guardar
                  </Button>
                </div>
                <div style={{ width: 105, height: 45 }}>
                  <Button
                    className="custom-button"
                    style={{
                      borderRadius: "4px",
                      backgroundColor: "#D15B48",
                      borderColor: "#D15B48",
                      height: "100%",
                    }}
                    onClick={onHandleCancel}
                  >
                    Cancelar
                  </Button>
                </div>
              </div>
            </>
          ) : (
            <Row>
              <Col span={16}>
                <FormItemComponent inputName="fecha" rules={[]}>
                  <DatePicker
                    name="fecha"
                    placeholder="Fecha"
                    style={{ height: 45, width: "100%" }}
                  />
                </FormItemComponent>
              </Col>
              <Col span={1}></Col>
              <Col span={7}>
                <Button
                  type="primary"
                  htmlType="submit"
                  className={"custom-button"}
                  style={{
                    backgroundColor: "#438EB9",
                    border: "1px solid #438EB9",
                  }}
                >
                  Guardar
                </Button>
              </Col>
            </Row>
          )}
        </Form>
      </Col>
    </Row>
  );
};

export default DaterForm;
