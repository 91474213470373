import React, { useEffect, useState } from "react";

import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  FormInstance,
  Image,
  message,
  Row,
  Space,
} from "antd";
import AuthenticatedRoute from "../../core/routes/AuthenticatedRoute";
import { Paths } from "../../config/paths";

import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  AttributeTypes,
  FormTypeEnum,
  InputNumberType,
  ReduxType,
} from "../../shared/types";
import {
  addProductToQuoteAction,
  updateQualitativeSelectedValue,
  cleanProductStateAction,
} from "../../store/products/actions";
import { InputNumber, InputSelect } from "../../components/inputs";
import { FormItemComponent } from "../../components/forms/formItemComponent";
import { Separator } from "../../components/separator";
import {
  findIndex,
  getBonif,
  getCostAndPriceFromProductQuote,
  getProductFinalCost,
  getProductSalePrice,
  getProfitability,
} from "../../shared/helper";
import * as _ from "lodash";

import { isNumber } from "lodash";
import { ProductDetailMenu } from "../../components/menu";
import { DropdownMenu } from "../../components/dropdownMenu";
import deleteIcon from "../../assets/icon/productDetail/delete.png";
import {
  IProductQuote,
  IProductQuoteAditionalComplements,
  IProductQuoteDetail,
  IQuoteDetailChildProduct,
  IQuoteDetailQualitative,
  IQuoteDetailQuantitative,
} from "../../core/models/products";
import NumberFormat from "react-number-format";
import {
  handleAditionalComplementChange,
  removeComplement,
} from "../../shared/helpers/complementsHelper";
import { hasPermission } from "../../core/auth/AuthService";

const ProductDetailPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [allowVerifyData, setAllowVerifyData] = useState(false);
  const [orderFinalCost, setOrderFinalCost] = useState(0);
  const [orderSalePrice, setOrderSalePrice] = useState(0);

  const { state }: any = useLocation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { clientData, ordersData, productData } = useSelector(
    (state: ReduxType) => {
      return state;
    }
  );

  const [formValid, setFormValid] = useState(false);

  useEffect(() => {
    verifyQuoteData();
    /* 
      allowVerifyData se inicializa en "false" para evitar que verifyQuoteData() se ejecute multiples veces
      mientras se renderiza el componente. 

      aca se coloca en true, para que cuando se genere un pedido a partir de una cotización, el useEffect que observa
      state.formType vuelva a verificar los datos de entrada.
    */
    setAllowVerifyData(true);
  }, []);

  useEffect(() => {
    if (allowVerifyData) {
      verifyQuoteData();
    }
  }, [state.formType]);

  useEffect(() => {
    const { productQuote } = productData;

    if (!_.isEmpty(productQuote)) {
      const total = getCostAndPriceFromProductQuote(productQuote, clientData);

      setOrderFinalCost(total.totalFinalCost);
      setOrderSalePrice(total.totalSalePrice);

      if (state.formType == FormTypeEnum.Order) {
        /* 
          En caso de ser un PEDIDO, cada vez que se haga una modificación a un producto
          se deben validar los valores seleccionados de los atributos CUALITATIVOS
          para determinar si se habilita el boton de GENERAR.
        */
        productQuote.every((product: IProductQuote) => {
          const {
            detalle: { qualitative },
          } = product;
          const valid = !qualitative?.some(
            (qualitative: IQuoteDetailQualitative) => {
              /* 
                CREATE: En modo crear pedido, la variable "selected" se crea por defualt en null, por eso compara directamente
                        el objeto.
                EDIT: al entrar en modo edición de un pedido existente, se tiene que inicializar el objeto con su respectiva estructura
                      pero con valores null, porque se debe evaluar si se le asignaran valores por defecto. de este modo se
                      evita un error de ejecución de "uso antes de asinción" (attributesHelper.tsx 71)

                Por esto se compara selected y selected.seleccionad._id con NULL
              */
              return (
                qualitative.selected == null ||
                qualitative.selected.seleccionado._id == null
              );
            }
          );
          setFormValid(valid || false);
          return valid || false;
        });
      }
    }
  }, [
    productData.productQuote, // en caso de se agreguen/eliminen productos
    state.formType, //en caso de que cambie el formulario de cotizacion a pedido.
  ]);

  useEffect(() => {
    if (
      (ordersData.orderQuote && state && state.from == Paths.ORDER) ||
      !clientData.client
    ) {
      verifyOrderAndGenerateQuote();
    }
  }, [ordersData.orderQuote]);

  const verifyOrderAndGenerateQuote = async () => {
    if (ordersData.orderQuote) {
      await dispatch(cleanProductStateAction());
      await dispatch(addProductToQuoteAction(ordersData.orderQuote.items));
    }
  };

  const verifyQuoteData = async () => {
    const { productQuote } = productData;

    if (productQuote.length == 0 || !clientData.client) {
      if (state && state.formType == FormTypeEnum.Quote) {
        navigate(Paths.NEWQUOTATION);
      } else {
        navigate(Paths.NEWORDER);
      }
    }
  };

  const cleanAndNavigate = async () => {
    await dispatch(cleanProductStateAction());
    navigate(
      state && state.formType == FormTypeEnum.Quote
        ? Paths.NEWQUOTATION
        : Paths.NEWORDER,
      {
        state: {
          from: Paths.PRODUCTDETAIL,
          formType: state.formType,
          editMode: state.editMode,
          canEdit: state.canEdit,
          ref: state && state.ref,
        },
      }
    );
  };

  const changeProductCount = async (value: any, index: number) => {
    const { productQuote } = productData;

    const updated = productQuote.map(
      (productQuote: any, innerIndex: number) => {
        if (innerIndex == index) {
          productQuote.cantidad = value || 1;
        }

        return productQuote;
      }
    );

    await dispatch(addProductToQuoteAction(updated));
  };

  const setDynamicSelectChange = async (
    value: any,
    mainProductId: number,
    selectedFatherProductId: number,
    outerQualitativeIndex: number,
    outerProductQuoteIndex: number,
    atrib_id: number
  ) => {
    const { productQuote } = productData;
    /* 
      atrib_id: en caso de ser un pedido/cotizacion existente, el atributo cuenta con un ID que lo identifica en la BD
      seleccionado:
        - _id: ID del atributo seleccionado en el selector
        - isMainProduct: determina si el atributo pertece al producto principal o los productos hijos
        - product_id: ID del producto padre del atributo seleccionado.
    */
    const val: any = {
      atrib_id: atrib_id,
      seleccionado: {
        _id: value.seleccionado?._id ? value.seleccionado._id : value,
        isMainProduct: mainProductId == selectedFatherProductId,
        product_id: selectedFatherProductId,
      },
    };

    const updated = productQuote.map(
      (InnerProductQuote: IProductQuote, InnerProductQuoteIndex: number) => {
        /* 
        outerProductQuoteIndex: Index del Producto cuyo selector activa el evento onChange en pantalla
        InnerProductQuoteIndex: Index del Producto actual iterando.

        estos deben ser iguales para proceder a la actualización del atributo seleccionado.
      */
        if (outerProductQuoteIndex == InnerProductQuoteIndex) {
          const { detalle } = InnerProductQuote;

          detalle.qualitative = detalle.qualitative?.map(
            (
              qualitative: IQuoteDetailQualitative,
              innerQualitativeIndex: number
            ) => {
              if (outerQualitativeIndex == innerQualitativeIndex) {
                qualitative = {
                  ...qualitative,
                  selected: val,
                  initialValue: value.seleccionado?._id
                    ? value.seleccionado._id
                    : value,
                };
              }
              return qualitative;
            }
          );
        }

        return InnerProductQuote;
      }
    );

    await dispatch(updateQualitativeSelectedValue(updated));
  };

  const handleSubmit = (values: any) => {
    if (!clientData.client) {
      return message.error("Debe seleccionar un cliente para poder continuar");
    }
    navigate(Paths.PRODUCTREFERENCE, {
      state: {
        formType: state && state.formType,
        editMode: state && state.editMode,
        canEdit: state && state.canEdit,
        ref: state && state.ref,
        newOrder: state && state.newOrder,
      },
    });
  };

  const handleCustomSubmit = () => {
    if (!clientData.client) {
      return message.error("Debe seleccionar un cliente para poder continuar");
    }
    navigate(Paths.PRODUCTREFERENCE, {
      state: {
        formType: state && state.formType,
        editMode: state && state.editMode,
        canEdit: state && state.canEdit,
        ref: state && state.ref,
        draft: true,
        newOrder: state && state.newOrder,
      },
    });
  };

  /*
    Esta es la funcion que ejectua el OnClick de "Generar Pedido"
    cuando entras en una Cotización y decides generar el pedido.

    de modo que se procesa como un nuevo pedido "newOrder" lleva el flag true
  */
  const handleCustomSubmitToGenerateOrder = () => {
    navigate(Paths.PRODUCTDETAIL, {
      state: {
        formType: FormTypeEnum.Order,
        from: `${Paths.ORDER}/:Order`,
        editMode: state && state.editMode,
        canEdit: state && state.canEdit,
        ref: state && state.ref,
        newOrder: true,
      },
    });
  };

  /**
   *
   * @param aditionalComplement
   * @param productQuoteIndex
   */
  const deleteComplement = async (
    aditionalComplement: IProductQuoteAditionalComplements,
    productQuoteIndex: number
  ) => {
    const { productQuote } = productData;
    const updated: IProductQuote[] = removeComplement(
      productQuote,
      productQuoteIndex,
      aditionalComplement
    );

    await dispatch(addProductToQuoteAction(updated));
  };

  /**
   *
   * @param productQuoteIndex
   * @param newQuantity
   * @param aditionalComplement
   */
  const onComplementChange = async (
    productQuoteIndex: number,
    newQuantity: number,
    aditionalComplement: IProductQuoteAditionalComplements
  ) => {
    /* newQuantity representa la nueva cantidad ingresada y aditionalComplement.quantity previa
       es necesario establecer que estos deben ser distintos para evitar que el proceso se repita multiples veces 
       innecesariamente. ej: al volver de la pantalla ComplementsPage tras agregar un complemento. */
    if (newQuantity && newQuantity != aditionalComplement.quantity) {
      /* Dado que en la pantalla de "ProductDetail" los elementos de complementos adicionales se muestran es porque
         actualmente existen dentro de productQuote.complementosAdicionales, entonces el flag "exists" se establece
         como "true" para que todos los cambios de cantidad en esta pantalla solo actualicen los complementos y no 
         generen duplicados.
      */
      const updated: IProductQuote[] = handleAditionalComplementChange(
        newQuantity,
        productQuoteIndex,
        productData.productQuote,
        { ...aditionalComplement, exists: true }
      );

      await dispatch(addProductToQuoteAction(updated));
    }
  };

  return (
    <AuthenticatedRoute
      path={Paths.PRODUCTDETAIL}
      withHeader
      goBack={() => {
        navigate((state && state.from) || Paths.ROOT, {
          state: {
            from: Paths.PRODUCTDETAIL,
          },
        });
      }}
      headerNavigationName={
        state && state.formType == FormTypeEnum.Quote
          ? "Detalle de la Cotización"
          : "Detalle del Pedido"
      }
    >
      {!isLoading && (
        <>
          <Form name="basic" layout="vertical" onFinish={handleSubmit}>
            {!_.isEmpty(productData.productQuote) &&
              productData.productQuote.map(
                (pq: IProductQuote, index: number) => {
                  const { detalle } = pq;

                  return (
                    <Col key={`productQuote-${index}`} span={24}>
                      <Card className={"card-product-detail"}>
                        <Separator>
                          <Row align="bottom">
                            <Col flex="auto">
                              Item:
                              <span
                                className={
                                  "custom-span-text custom-span-text-font"
                                }
                              >
                                {" "}
                                {pq.item}
                              </span>
                            </Col>
                            <Col flex="100px" style={{ textAlign: "right" }}>
                              <Space align="center" direction="vertical">
                                <span className="custom-span-text custom-span-text-font">
                                  Acciones
                                </span>
                                <ProductDetailMenu
                                  productQuoteElement={pq}
                                  productQuoteIndex={index}
                                />
                              </Space>
                            </Col>
                          </Row>
                        </Separator>
                        <Separator marginTop={2}>
                          Producto:
                          <span
                            className={"custom-span-text custom-span-text-font"}
                          >
                            {" "}
                            {pq.producto.descripcion}
                          </span>
                        </Separator>

                        {detalle.quantitative &&
                          detalle.quantitative.map(
                            (
                              quantitative: IQuoteDetailQuantitative,
                              InnerIndex: number
                            ) => {
                              return (
                                <Separator
                                  key={`attr-quantitative-${InnerIndex}`}
                                  marginTop={2}
                                >
                                  {quantitative.name}
                                  <span
                                    className={
                                      "custom-span-text custom-span-text-font"
                                    }
                                  >
                                    {" "}
                                    {`${parseFloat(
                                `${quantitative.value?.base}`
                              ).toFixed(3)} mts `}{" "}
                                    {quantitative.value?.altura &&
                                      `x ${parseFloat(
                                  `${quantitative.value?.altura}`
                                ).toFixed(3)} mts`}
                                  </span>
                                </Separator>
                              );
                            }
                          )}

                        {detalle.childProducts &&
                          detalle.childProducts.map(
                            (
                              childProducts: IQuoteDetailChildProduct,
                              InnerIndex: number
                            ) => {
                              return (
                                <Separator
                                  key={`childProducts-${InnerIndex}`}
                                  marginTop={2}
                                >
                                  {childProducts.name}:
                                  <span
                                    className={
                                      "custom-span-text custom-span-text-font"
                                    }
                                  >
                                    {" "}
                                    {childProducts.description}
                                  </span>
                                </Separator>
                              );
                            }
                          )}

                        <Separator marginTop={10} key={`Detail-Map-${index}`}>
                          <FormItemComponent
                            inputName={"count"}
                            rules={[
                              { name: "required" },
                              {
                                name: "minOrMax",
                                min: 1,
                                max: Number.MAX_SAFE_INTEGER,
                              },
                            ]}
                          >
                            <InputNumber
                              enabled
                              required
                              showPlaceholder
                              initialValue={pq.cantidad}
                              onChange={(value) =>
                                changeProductCount(value, index)
                              }
                              type={InputNumberType.Number4}
                              name={"count"}
                              placeholder={"Cantidad"}
                              style={{ height: 45 }}
                              min={1}
                            />
                          </FormItemComponent>
                        </Separator>
                        <Separator>
                          <Row>
                            {hasPermission("READ_FINAL_COST") ? (
                              <Col span={12}>
                                <div>
                                  <b>Costo final</b>
                                </div>
                                <NumberFormat
                                  value={getProductFinalCost(
                                    pq.precio,
                                    clientData,
                                    pq.cantidad,
                                    true
                                  )}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  prefix={"$"}
                                />
                              </Col>
                            ) : null}
                            <Col span={12}>
                              <div>
                                <b>Precio de venta</b>
                              </div>
                              <NumberFormat
                                value={getProductSalePrice(
                                  pq.precio,
                                  pq.cantidad,
                                  getProfitability(clientData),
                                  getBonif(clientData)
                                )}
                                displayType={"text"}
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                prefix={"$"}
                              />
                            </Col>
                          </Row>
                        </Separator>

                        {state.formType == FormTypeEnum.Order &&
                          detalle.qualitative &&
                          detalle.qualitative.length > 0 && (
                            <>
                              <Separator
                                key={`qualitative-title-${index + 1}`}
                                marginTop={15}
                                marginBottom={15}
                              >
                                <Space>
                                  <span
                                    style={{
                                      fontStyle: "normal",
                                      fontWeight: "bold",
                                      fontSize: 18,
                                      lineHeight: "16px",
                                      letterSpacing: 0.4,
                                      color: "#706F6E",
                                    }}
                                  >
                                    Elegir detalles
                                  </span>
                                </Space>
                              </Separator>

                              {detalle.qualitative.map(
                                (
                                  qualitative: IQuoteDetailQualitative,
                                  qualitaiveIndex: number
                                ) => {
                                  return (
                                    <Form.Item
                                      key={`qualitative-option-${qualitaiveIndex}`}
                                      validateTrigger={["onChange", "onBlur"]}
                                    >
                                      {qualitative.items && (
                                        <>
                                          <Separator>
                                            <FormItemComponent
                                              inputName={`${pq.item}-${qualitative.descripcion_comercial}`}
                                              rules={[{ name: "required" }]}
                                              message={`El campo ${qualitative.descripcion_comercial.replace(
                                                ":",
                                                ""
                                              )} es obligatorio.`}
                                            >
                                              <InputSelect
                                                enabled
                                                required
                                                showPlaceholder
                                                initialValue={
                                                  qualitative.initialValue
                                                }
                                                onChange={(value) =>
                                                  setDynamicSelectChange(
                                                    value,
                                                    pq.producto._id,
                                                    qualitative.producto_id,
                                                    qualitaiveIndex,
                                                    index,
                                                    qualitative.selected
                                                      ?.atrib_id
                                                  )
                                                }
                                                name={`${pq.item}-${qualitative.descripcion_comercial}`}
                                                placeholder={`${qualitative.descripcion_comercial}`}
                                                optionList={qualitative.items}
                                              />
                                            </FormItemComponent>
                                          </Separator>
                                        </>
                                      )}
                                    </Form.Item>
                                  );
                                }
                              )}
                            </>
                          )}

                        {pq.complementosAdicionales &&
                          pq.complementosAdicionales.length > 0 && (
                            <>
                              <Separator marginTop={20}>
                                <Space>
                                  <span
                                    className={"custom-span-text section-title"}
                                  >
                                    Complementos
                                  </span>
                                </Space>
                              </Separator>

                              {pq.complementosAdicionales.length > 0 &&
                                pq.complementosAdicionales.map(
                                  (
                                    value: IProductQuoteAditionalComplements,
                                    adIndex: number
                                  ) => {
                                    const { price, quantity } = value;

                                    return (
                                      <div
                                        key={`complementosAdicionales-${adIndex}`}
                                      >
                                        {!pq._id && (
                                          <Separator marginTop={10}>
                                            <Row>
                                              <Col span={23}>
                                                <span
                                                  className={
                                                    "custom-span-text "
                                                  }
                                                >
                                                  Código:
                                                </span>{" "}
                                                {value.code}
                                              </Col>
                                              <Col span={1}>
                                                <DropdownMenu
                                                  menu={[
                                                    {
                                                      name: "Eliminar",
                                                      action: () =>
                                                        deleteComplement(
                                                          value,
                                                          index
                                                        ),
                                                      icon: (
                                                        <Image
                                                          className={
                                                            "menu-custom-icon"
                                                          }
                                                          src={deleteIcon}
                                                          preview={false}
                                                        />
                                                      ),
                                                    },
                                                  ]}
                                                />
                                              </Col>
                                            </Row>
                                          </Separator>
                                        )}

                                        <Separator marginTop={2}>
                                          <span className={"custom-span-text"}>
                                            Producto:
                                          </span>{" "}
                                          {value.description}
                                        </Separator>
                                        <Separator
                                          marginTop={20}
                                          key={`Complement-Map-${adIndex}`}
                                        >
                                          <FormItemComponent
                                            inputName={"count"}
                                            rules={[{ name: "required" }]}
                                          >
                                            <InputNumber
                                              enabled={!pq._id}
                                              required
                                              showPlaceholder
                                              initialValue={value.quantity}
                                              onChange={(valor) =>
                                                onComplementChange(
                                                  index,
                                                  valor,
                                                  value
                                                )
                                              }
                                              type={InputNumberType.Number4}
                                              name={"count"}
                                              placeholder={"Cantidad"}
                                              style={{ height: 45 }}
                                              min={1}
                                            />
                                          </FormItemComponent>
                                        </Separator>
                                        <Separator marginTop={2}>
                                          <span className={"custom-span-text"}>
                                            Costo final:
                                          </span>{" "}
                                          <NumberFormat
                                            value={getProductFinalCost(
                                              price,
                                              clientData,
                                              quantity,
                                              true
                                            )}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            prefix={"$"}
                                          />
                                        </Separator>
                                        <Separator marginTop={2}>
                                          <span className={"custom-span-text"}>
                                            Precio de venta:
                                          </span>{" "}
                                          <NumberFormat
                                            value={getProductSalePrice(
                                              price,
                                              quantity,
                                              getProfitability(clientData),
                                              getBonif(clientData)
                                            )}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            prefix={"$"}
                                          />
                                        </Separator>
                                        {adIndex !==
                                          pq.complementosAdicionales.length -
                                            1 && (
                                          <Divider
                                            type="horizontal"
                                            className="custom-divider"
                                          />
                                        )}
                                      </div>
                                    );
                                  }
                                )}
                            </>
                          )}
                      </Card>
                    </Col>
                  );
                }
              )}
            {hasPermission("READ_FINAL_COST") ? (
              <Separator colStyle={{ textAlign: "right" }} marginTop={10}>
                <Space>
                  <span
                    className={
                      "custom-span-text custom-span-text-font black-font"
                    }
                  >
                    Costo Final
                  </span>
                  <span className={"custom-span-text"}>
                    <NumberFormat
                      value={orderFinalCost.toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      prefix={"$"}
                    />
                  </span>
                </Space>
              </Separator>
            ) : null}
            <Separator colStyle={{ textAlign: "right" }} marginTop={5}>
              <Space>
                <span
                  className={
                    "custom-span-text custom-span-text-font black-font"
                  }
                >
                  Precio de venta
                </span>
                <span className={"custom-span-text"}>
                  <NumberFormat
                    value={orderSalePrice.toFixed(2)}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    prefix={"$"}
                  />
                </span>
              </Space>
            </Separator>

            {state &&
              state.formType == FormTypeEnum.Quote &&
              state.editMode && (
                <Separator marginTop={20}>
                  <Button
                    type="primary"
                    onClick={() => handleCustomSubmitToGenerateOrder()}
                    className={"custom-button"}
                    style={{
                      backgroundColor: "#FFFFFF",
                      color: "#828282",
                    }}
                  >
                    Generar Pedido
                  </Button>
                </Separator>
              )}

            <Separator marginTop={20}>
              <Button
                type="primary"
                onClick={() => cleanAndNavigate()}
                className={"custom-button"}
                style={{
                  backgroundColor: "#FFFFFF",
                  color: "#828282",
                }}
              >
                Agregar Nuevo Producto
              </Button>
            </Separator>

            {state && state.formType == FormTypeEnum.Order && (
              <Separator marginTop={20}>
                <Button
                  type="primary"
                  onClick={() => handleCustomSubmit()}
                  className={"custom-button"}
                  style={{
                    backgroundColor: "#FFFFFF",
                    color: "#828282",
                  }}
                  disabled={
                    state.formType == FormTypeEnum.Quote
                      ? !clientData.client
                      : !formValid
                  }
                >
                  Guardar como Borrador
                </Button>
              </Separator>
            )}

            {state &&
              state.formType == FormTypeEnum.Quote &&
              state &&
              state.editMode && (
                <Separator marginTop={20}>
                  <Button
                    type="primary"
                    onClick={() =>
                      navigate(
                        state.formType == FormTypeEnum.Quote
                          ? Paths.QUOTATION
                          : Paths.ORDER
                      )
                    }
                    className={"custom-button"}
                    style={{
                      backgroundColor: "#FFFFFF",
                      color: "#828282",
                    }}
                  >
                    Volver
                  </Button>
                </Separator>
              )}

            <Separator marginTop={20} marginBottom={20}>
              <Form.Item shouldUpdate className="submit">
                {() => (
                  <Button
                    type="primary"
                    className={"custom-button custom-button-blue"}
                    htmlType="submit"
                    disabled={
                      state.formType == FormTypeEnum.Quote
                        ? !clientData.client
                        : !formValid
                    }
                  >
                    {state && state.formType == FormTypeEnum.Quote
                      ? "Guardar Cotización"
                      : "Generar pedido"}
                  </Button>
                )}
              </Form.Item>
            </Separator>
          </Form>
        </>
      )}
    </AuthenticatedRoute>
  );
};

export default ProductDetailPage;
