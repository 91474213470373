import * as types from "./actionTypes";

const initialState = {
  dater: undefined,
  loading: false,
  error: {
    message: "",
  },
};

const DaterReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  const actionType = action.type;
  const actionPayload = action.payload;
  switch (actionType) {
    case types.GET_DATER:
      state = { ...state, loading: true };
      break;

    case types.GET_DATER_SUCCESS:
      state = { ...state, dater: actionPayload, loading: false };
      break;

    case types.GET_DATER_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loading: false,
      };
      break;
  }

  return state;
};

export default DaterReducer;
