import { ClientDataProps } from "../../shared/types";
import * as types from "./actionTypes";

export const setClientAction = (client: ClientDataProps) => {
  return {
    type: types.SET_CLIENT,
    client,
  };
};

export const setClientFail = (error: any) => {
  return {
    type: types.SET_CLIENT_FAIL,
    payload: error,
  };
};

export const setClientSuccess = (client: any) => {
  return {
    type: types.SET_CLIENT_SUCCESS,
    payload: client,
  };
};

export const getClientsAction = (clientsUrl: string) => {
  return {
    type: types.GET_CLIENTS,
    clientsUrl,
  };
};

export const getClientsFail = (error: any) => {
  return {
    type: types.GET_CLIENTS_FAIL,
    payload: error,
  };
};

export const getClientsSuccess = (clients: any) => {
  return {
    type: types.GET_CLIENTS_SUCCESS,
    payload: clients,
  };
};

export const getClientByIdAction = (clientId: string) => {
  return {
    type: types.GET_CLIENT_ID,
    clientId,
  };
};

export const getClientByIdFail = (error: any) => {
  return {
    type: types.GET_CLIENT_ID_FAIL,
    payload: error,
  };
};

export const getClientByIdSuccess = (clients: any) => {
  return {
    type: types.GET_CLIENT_ID_SUCCESS,
    payload: clients,
  };
};

export const cleanClientStateAction = () => {
  return {
    type: types.CLEAN_CLIENT_STATE,
  };
};

export const cleanClientStateFail = (error: any) => {
  return {
    type: types.CLEAN_CLIENT_STATE_FAIL,
    payload: error,
  };
};

export const cleanClientStateSuccess = () => {
  return {
    type: types.CLEAN_CLIENT_STATE_SUCCESS,
  };
};
