import * as types from "./actionTypes";

const initialState = {
  clients: undefined,
  client: undefined,
  loading: false,
  error: {
    message: "",
  },
};

const ClientReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  const actionType = action.type;
  const actionPayload = action.payload;
  switch (actionType) {
    case types.GET_CLIENTS:
    case types.GET_CLIENT_ID:
    case types.SET_CLIENT:
    case types.CLEAN_CLIENT_STATE:
      state = { ...state, loading: true };
      break;

    case types.GET_CLIENTS_SUCCESS:
      state = { ...state, clients: actionPayload, loading: false };
      break;

    case types.SET_CLIENT_SUCCESS:
    case types.GET_CLIENT_ID_SUCCESS:
      state = { ...state, client: actionPayload, loading: false };
      break;

    case types.CLEAN_CLIENT_STATE_SUCCESS:
      state = { ...state, client: undefined, loading: false };
      break;

    case types.GET_CLIENTS_FAIL:
    case types.GET_CLIENT_ID_FAIL:
    case types.SET_CLIENT_FAIL:
    case types.CLEAN_CLIENT_STATE_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loading: false,
      };
      break;
  }

  return state;
};

export default ClientReducer;
