import React from "react";
import { Modal } from "antd";
import { ConfirmDialogProps, ConfirmDialogTypeEnum } from "../../shared/types";
const { confirm } = Modal;

export default function (props: ConfirmDialogProps) {
  const { title, message, type, okText, onOk, onCancel } = props;

  switch (type) {
    case ConfirmDialogTypeEnum.Exito: {
      Modal.success({
        title,
        content: message,
        okText: okText || "Aceptar",
        onOk,
      });

      break;
    }
    case ConfirmDialogTypeEnum.Advertencia: {
      Modal.warning({
        title: title || "Advertencia",
        content: message,
        okText: okText || "Aceptar",
        onOk,
      });

      break;
    }
    case ConfirmDialogTypeEnum.Info: {
      Modal.info({
        title,
        content: message,
        okText: okText || "Aceptar",
        onOk,
      });

      break;
    }
    case ConfirmDialogTypeEnum.Error: {
      Modal.error({
        title: title || "Error",
        content: message,
        okText: okText || "Aceptar",
        onOk,
      });

      break;
    }
    case ConfirmDialogTypeEnum.Eliminar: {
      confirm({
        title: title || "Advertencia",
        content: message,
        okText: "Cancelar",
        okType: "default",
        cancelButtonProps: { className: "ant-btn ant-btn-dangerous" },
        cancelText: okText || "Aceptar",
        autoFocusButton: "cancel",
        onCancel: onOk,
        onOk: onCancel,
      });
      break;
    }
  }
}
