import React, { useEffect, useState } from "react";

import { Button, Col, Form, FormInstance, message, Row, Upload } from "antd";
import AuthenticatedRoute from "../../core/routes/AuthenticatedRoute";
import { Paths } from "../../config/paths";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { InputNumberType, ReduxType } from "../../shared/types";
import { InputNumber, InputText, InputTextArea } from "../../components/inputs";

import { FormItemComponent } from "../../components/forms/formItemComponent";
import { RcFile } from "antd/lib/upload";
import { apiPut, getHeadersWithoutContentType } from "../../shared/ApiService";
import { API_URL } from "../../config/general-config";
import { Endpoints } from "../../config/endpoints";
import { getConfigurationParamsAction } from "../../store/configuration/actions";
import { CustomModal } from "../../components/modal";
import okModalIcon from "../../assets/icon/modalIcons/ok.png";
import { Separator } from "../../components/separator";

const ConfigurationPage = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const formRef = React.createRef<FormInstance>();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { configurationData, userData } = useSelector((state: ReduxType) => {
    return state;
  });

  useEffect(() => {
    doRequest();
  }, []);

  useEffect(() => {
    if (configurationData.loading) setIsLoaded(true);
    if (!isLoaded && configurationData && configurationData.params) {
      setIsLoaded(true);
    }
  }, [configurationData]);

  const doRequest = async () => {
    const userId = 
      userData &&
      userData.userProfile &&
      typeof userData.userProfile === "string"
        ? JSON.parse(userData.userProfile)
        : userData.userProfile;

    const finalId = userId ? userId._id : "";
    const url = `${API_URL}/${Endpoints.CONFIGURATION_BY_USER}${finalId}`;

    await dispatch(getConfigurationParamsAction(url));
  };

  const handleSubmit = async (values: any) => {
    const body = {
      ...configurationData.params,
      rentabilidad: values.rentabilidad,
      plazo: values.plazo,
      condicion: values.condicion,
      validez: values.validez,
      observaciones: values.observaciones
    };

    setIsLoading(true);
    Promise.all([
      apiPut({
        url: `${API_URL}/${Endpoints.CONFIGURATION_PARAMS}/${configurationData.params._id}`,
        unauthorizedCallback: () => {},
        body,
      }),
    ]).then((values) => {
        setIsModalVisible(true);
    });
    setIsLoading(false);
    await doRequest();
  };

  return (
    <AuthenticatedRoute
      path={Paths.CONFIGURATION}
      withHeader
      goBack={() => navigate(Paths.ROOT)}
      headerNavigationName={"Mi Configuracion"}
    >
      {isLoaded && (
        <Form
          name="basic"
          onValuesChange={() => {}}
          onFinish={handleSubmit}
          layout="vertical"
          ref={formRef}
        >
          <Separator marginTop={25}>
            <span>Rentabilidad (%)</span>
            <FormItemComponent
              inputName="rentabilidad"
              rules={[{ name: "required" }]}
            >
              <InputNumber
                name={"rentability"}
                showPlaceholder
                type={InputNumberType.Percentage4}
                enabled
                required
                initialValue={
                  configurationData.params
                    ? configurationData.params.rentabilidad
                    : undefined
                }
                style={{ display: "flex", height: 45 }}
              />
            </FormItemComponent>
          </Separator>
          <Separator marginTop={5}>
            <span>Plazo de entrega</span>
            <FormItemComponent
              inputName="plazo"
              rules={[]}
            >
              <InputText
                enabled
                initialValue={
                  configurationData.params
                    ? configurationData.params.plazo
                    : undefined
                }
                name="plazo"
                placeholder="Plazo"
                showPlaceholder
                required={false}
                type="text"
            />
            </FormItemComponent>
          </Separator>
          <Separator marginTop={5}>
            <span>Condición de pago</span>
            <FormItemComponent
              inputName="condicion"
              rules={[]}
            >
              <InputText
                enabled
                initialValue={
                  configurationData.params
                    ? configurationData.params.condicion
                    : undefined
                }
                name="condicion"
                placeholder="Condición"
                showPlaceholder
                required={false}
                type="text"
            />
            </FormItemComponent>
          </Separator>
          <Separator marginTop={5}>
            <span>Validez de precios</span>
            <FormItemComponent
              inputName="validez"
              rules={[]}
            >
              <InputText
                enabled
                initialValue={
                  configurationData.params
                    ? configurationData.params.validez
                    : undefined
                }
                name="validez"
                placeholder="Validez de precios"
                showPlaceholder
                required={false}
                type="text"
            />
            </FormItemComponent>
          </Separator>
          <Separator marginTop={5}>
            <span>Observaciones de la cotización</span>
            <FormItemComponent
              inputName="observaciones"
              rules={[]}
            >
              <InputTextArea
                  enabled
                  showPlaceholder
                  initialValue={
                    configurationData.params
                      ? configurationData.params.observaciones
                      : undefined
                  }
                  type={"text"}
                  name={"observaciones"}
                  placeholder={"Observaciones de la cotización"}
                  required={false}
                  style={{ height: 45 }}
                />
            </FormItemComponent>
          </Separator>

          <Separator marginTop={15}>
            <Button
              type="primary"
              htmlType="submit"
              disabled={isLoading}
              className="custom-button custom-button-blue"
              style={{
                backgroundColor: isLoading ? "grey !important" : "#2D9CDB",
                width: "100%",
              }}
            >
              Guardar
            </Button>
          </Separator>
        </Form>
      )}
      <CustomModal
        closable={false}
        isModalVisible={isModalVisible}
        handleOk={() => setIsModalVisible(false)}
        handleCancel={() => setIsModalVisible(false)}
        image={okModalIcon}
        contentMessage="La nueva configuración ha sido guardada"
      />
    </AuthenticatedRoute>
  );
};

export default ConfigurationPage;
