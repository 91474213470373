import React, { useEffect, useState } from "react";

import { Button, Form, FormInstance, message } from "antd";
import AuthenticatedRoute from "../../core/routes/AuthenticatedRoute";
import { Paths } from "../../config/paths";

import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FormTypeEnum, ReduxType } from "../../shared/types";

import { InputText, InputTextArea } from "../../components/inputs";
import { FormItemComponent } from "../../components/forms/formItemComponent";
import { Separator } from "../../components/separator";
import * as _ from "lodash";
import moment from "moment";
import { saveQuoteAction } from "../../store/quotes/actions";
import { cleanClientStateAction } from "../../store/clients/actions";
import { addProductToQuoteAction } from "../../store/products/actions";
import { CustomModal } from "../../components/modal";
import okModalIcon from "../../assets/icon/modalIcons/ok.png";
import { saveOrderAction } from "../../store/orders/actions";
import { findIndex, generateAttrData } from "../../shared/helper";
import { IProductQuote, IProductQuoteAditionalComplements, IQuoteDetailChildProduct, IQuoteDetailQualitative } from "../../core/models/products";
import { isClient } from "../../core/auth/AuthService";
import { getConfigurationParamsAction } from "../../store/configuration/actions";
import { Endpoints } from "../../config/endpoints";
import { API_URL } from "../../config/general-config";

const ProductReferencePage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [elementToCreate, setElementToCreate] = useState<any>();
  const [dataToRender, setDataToRender] = useState<any>();

  const formRef = React.createRef<FormInstance>();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { state }: any = useLocation();
  
  const showSubmitText = () => {
    if (state?.draft === true) {
      return "Guardar Borrador"
    } else if (state && state.formType == FormTypeEnum.Quote) {
      return "Guardar Cotización"
    } else {
      return "Guardar Pedido"
    }
  }

  const { productData, quoteData, ordersData, clientData, userData, configurationData } = useSelector(
    (state: ReduxType) => {
      return state;
    }
  );

  const selector: any = useSelector(state => state);

  useEffect(() => 
  {
    getParams();
  },[]);

  const getParams = async () => {
    const userId = 
    userData &&
    userData.userProfile &&
    typeof userData.userProfile === "string"
      ? JSON.parse(userData.userProfile)
      : userData.userProfile;

    const finalId = userId ? userId._id : "";
    const url = `${API_URL}/${Endpoints.CONFIGURATION_BY_USER}${finalId}`;

    await dispatch(getConfigurationParamsAction(url));
  };

  useEffect(() => {
    if (
      (productData.productQuote && productData.productQuote.length == 0) ||
      !clientData.client
    ) {
      navigate(Paths.NEWQUOTATION);
    }
  }, [productData.productQuote]);

  useEffect(() => {
    /* en caso de ser un Pedido */
    if (ordersData.orderQuote && clientData.client) {
      verifyQuoteData(ordersData.orderQuote);
    }
  }, [ordersData.orderQuote, clientData.client]);

  useEffect(() => {
    if (quoteData.quoteToDetail && clientData.client) {
      verifyQuoteData(quoteData.quoteToDetail);
    }
  }, [quoteData.quoteToDetail, clientData.client]);

  const verifyQuoteData = async (quoteElements: any) => {
    try {
      await setIsLoading(true);
      await setDataToRender(quoteElements);
    } catch (error: any) {
      message.error(error, 4);
    } finally {
      await setIsLoading(false);
    }
  };

  /**
   * 
   * @param qualitativeAttributes 
   * @returns atributos CUALITATIVOS que unicamente pertenezcan al producto PRINNCIPAL en el formato que requiere el backend
   */
  const qualitativeToPropDeLosAtributos = ( qualitativeAttributes: IQuoteDetailQualitative[] ) => {
    
    return qualitativeAttributes.filter(( qualitative:IQuoteDetailQualitative ) => {
      const { selected : { seleccionado }} = qualitative;
      return seleccionado.isMainProduct;
    }).map(( qualitative:IQuoteDetailQualitative ) => {
      
      const { selected, selected : { seleccionado:attribute }} = qualitative;

        if (selected.atrib_id) {
          return {
            atrib_id: selected.atrib_id,
            seleccionado: {
              _id: attribute._id
            }
          };
        } else {          
          return {
            seleccionado: {
              _id: attribute._id,
            }
          }       
        }
      
    });
  };

  /**
   * 
   * @param qualitativeAttributes 
   * @returns atributos CUALITATIVOS que unicamente pertenezcan a los productos HIJOS en el formato que requiere el backend
   */
  const qualitativeToPropDeLosProductosHijos = ( qualitativeAttributes: IQuoteDetailQualitative[] ) => {

    return qualitativeAttributes.filter(( qualitative:IQuoteDetailQualitative ) => {
      const { selected : { seleccionado }} = qualitative;
      return !seleccionado.isMainProduct;
    }).map(( qualitative:IQuoteDetailQualitative ) => {
      
      const { selected, selected : { seleccionado:attribute }} = qualitative;

        if (selected.atrib_id) 
        {
          return {
              _id:selected.atrib_id,
              producto_id:attribute.product_id,
              seleccionado: {
                _id: attribute._id,
                producto_id:attribute.product_id
              }
          }

        } else {
          return {
            producto_id:attribute.product_id,
            seleccionado: {
              _id: attribute._id,
              producto_id:attribute.product_id
            }
          }       
        }
      
    });
  };

  const handleSubmit = async (values: any) => {
    try {
      const client = { ...clientData.client };
      let toCreate: any = {
        nueva: true,
        fecha: moment().format("DD/MM/YYYY"),
        is_externo: isClient(),        
        is_mobile: true,
        items: _.map(productData.productQuote, (pq: IProductQuote) => {

          const { childProducts } = pq.detalle;
          const dataToSave: any = {
            _id                     : pq._id,
            atributos               : generateAttrData( pq.detalle ),            
            productos_hijos         : [], // JSON DIFF STG 
            complementos            : [], // JSON DIFF STG 
            complementosNecesarios  : pq.complementosNecesarios == "{}" ? [] : pq.complementosNecesarios,
            complementosAdicionales : pq.complementosAdicionales?.map(( aditional : IProductQuoteAditionalComplements ) => {
              return {
                  _id      : aditional.id,
                  cantidad : aditional.quantity,
                  precio   : aditional.price,
                  producto : { _id : aditional.productId }
                }              
            }),
            producto                : { _id: pq.producto._id },
            precio                  : pq.precio,
            cantidad                : pq.cantidad,
            nroIngreso              : pq.item,
            editado                 : false,
            propDeLosProductosHijos : [],
            productosHijos          : _.map(childProducts, (h:IQuoteDetailChildProduct) => {
                                          return {
                                            _id: h._id, 
                                            seleccionado: { _id: h.value } 
                                          };
                                      })
          };
          
          if (state.formType == FormTypeEnum.Order) {
            /* 
              se inicializan los valores seleccionados de:
              Atributos CUALITATIVOS

              Estos atributos seleccionados pertenecen al producto PRINCIPAL
            */
            dataToSave.propDeLosAtributos = pq.detalle.qualitative && qualitativeToPropDeLosAtributos( [...pq.detalle.qualitative] );
            dataToSave.tieneProps         = dataToSave.propDeLosAtributos?.length > 0;

            /* 
              se inicializan los valores seleccionados de:
              Atributos CUALITATIVOS

              Estos atributos seleccionados pertenecen al/los producto/s HIJOS
            */
            dataToSave.propDeLosProductosHijos = pq.detalle.qualitative && qualitativeToPropDeLosProductosHijos( [...pq.detalle.qualitative] );
            dataToSave.tienePropsHijos         = dataToSave.propDeLosProductosHijos?.length > 0;


            // dataToSave.complementosAdicionales = pq.complementosAdicionales?.map(( aditional : IProductQuoteAditionalComplements ) => {
            //   return {
            //       _id      : aditional.id,
            //       cantidad : aditional.quantity,
            //       precio   : aditional.price,
            //       producto : { _id : aditional.productId }
            //     }              
            // });

          }

          return dataToSave;
        }),
        total: _.sumBy(productData.productQuote, ( product : IProductQuote ) => {
                  const { complementosAdicionales:complements } = product;

                  const productPrice      = product.cantidad * product.precio;
                  const complementsPrice  = _.sumBy( complements, ( complement: IProductQuoteAditionalComplements ) => 
                                            { 
                                              return complement.price * complement.quantity; 
                                            });

                  return productPrice + complementsPrice;
                }),
        cliente: {
          ...client,
        },
        cliente_id: clientData.client._id,
        referencia: values.referencia,
        observaciones: values.observaciones,
        plazo: values.plazo,
        condicion: values.condicion,
        validez: values.validez,
        observacionesAdicionales: values.observacionesAdicionales,
        lista: {
          _id: productData.productPriceList?._id,
        },
      };

      /* 
        Aca se inicializan los campos principales que unincamente tienen que ser enviados
        en caso de ser un PEDIDO.
      */
      if(state.formType == FormTypeEnum.Order)
      {
        toCreate = {
          ...toCreate,
          is_borrador: state.draft || false
        }
      }

      if (productData.productQuote[0].cotizacionId) 
      {
        toCreate.cotizacionId = productData.productQuote[0].cotizacionId;
      }

      if (dataToRender) {
        toCreate = {
          ...toCreate,
          anulado: dataToRender.anulado,
          aprobado: dataToRender.aprobado,
          bonificacion: dataToRender.bonificacion,
          created_at: dataToRender.created_at,
          cuit_facturacion: dataToRender.cuit_facturacion,
          deleted_at: dataToRender.deleted_at,
          en_produccion: dataToRender.en_produccion,
          fecha_aprobacion: dataToRender.fecha_aprobacion,
          fecha_entrega: dataToRender.fecha_entrega,
          forma_entrega: dataToRender.forma_entrega,          
          itemsBorrados: productData.productQuoteToDelete
        };

        if(!state.newOrder && dataToRender._id)
        {
          toCreate = {
            ...toCreate,
            _id               : dataToRender._id,
            itemsActualizados : toCreate.items.filter((item: any) => item._id ),
            items             : toCreate.items.filter((item: any) => !item._id )
          }          
        }
      }

      if (state && state.formType == FormTypeEnum.Order && toCreate._id) 
      {
        (toCreate.plazo_entrega_id = dataToRender.plazo_entrega_id
          ? dataToRender.plazo_entrega_id
          : null),
          (toCreate.plazo_entrega = dataToRender.plazo_entrega
            ? dataToRender.plazo_entrega
            : null);
      }

      if (state && state.formType == FormTypeEnum.Quote) {
        await dispatch(saveQuoteAction(toCreate));
      } else if (state && state.formType == FormTypeEnum.Order) {
        await dispatch(saveOrderAction(toCreate));
      }

      await setElementToCreate(toCreate);
    } catch (error: any) {
      message.error(error);
    }
  };

  const cleanAndNavigate = async () => {
    await dispatch(addProductToQuoteAction([]));
    await dispatch(cleanClientStateAction());
    setIsModalVisible(false);
    navigate(Paths.ROOT);
  };

  const getMessage = () => {
    let message;
    if (state && state.draft) {
      message = [
        `Ud. ha ingresado un pedido en formato borrador.`,
        `Podrá editar y confirmar este detalle mas adelante ingresando a Mis borradores.`,
      ];
    } else {
      if (state && state.formType == FormTypeEnum.Quote) {
        message = [
          `Usted ha guardado la cotización ${
            elementToCreate ? elementToCreate.referencia : ""
          }.`,
          `Puede aprobar esta cotización ingresando a Mis cotizaciones.`,
        ];
      } else {
        message = [
          `Ha ingresado su pedido ref.: ${
            elementToCreate ? elementToCreate.referencia : ""
          }.`,
          `Ud. dispone de 24hs para modificar este pedido.`,
          `Pasado este plazo, las modificaciones y/o anulaciones demandarán un
        cargo segun el estado de producción en que se encuentre dicho pedido.`,
        ];
      }
    }
    return message;
  };

  return (
    <AuthenticatedRoute
      path={Paths.PRODUCTREFERENCE}
      withHeader
      goBack={() =>
        navigate(Paths.PRODUCTDETAIL, {
          state: {
            formType: state && state.formType,
            editMode: state && state.editMode,
            canEdit: state && state.canEdit,
            ref: state && state.ref,
          },
        })
      }
      headerNavigationName={"Indique referencia"}
    >
      {!isLoading && (
        <>
          <CustomModal
            closable={false}
            isModalVisible={!_.isEmpty(ordersData.orderSaved) || !_.isEmpty(quoteData.quote)}
            handleOk={() => cleanAndNavigate()}
            handleCancel={() => cleanAndNavigate()}
            image={okModalIcon}
            contentMessage={getMessage()}
          />

          <Form
            name="basic"
            initialValues={undefined}
            onFinish={handleSubmit}
            layout="vertical"
            ref={formRef}
          >
            <Separator marginTop={20}>
              <span>Referencia</span>
              <FormItemComponent
                inputName={"referencia"}
                rules={[{ name: "required" }]}
              >
                <InputText
                  enabled
                  required
                  showPlaceholder
                  type={"text"}
                  name={"referencia"}
                  placeholder={"Referencia"}
                  initialValue={
                    (dataToRender && dataToRender.referencia) ||
                    (state && state.ref)
                  }
                  style={{ height: 45 }}
                />
              </FormItemComponent>
            </Separator>
            <Separator marginTop={5}>
              <span>Observaciones</span>
              <FormItemComponent inputName={"observaciones"}>
                <InputTextArea
                  enabled
                  required
                  showPlaceholder
                  type={"text"}
                  name={"observaciones"}
                  placeholder={"Observaciones"}
                  initialValue={dataToRender && dataToRender.observaciones}
                  style={{ height: 45 }}
                />
              </FormItemComponent>    
            </Separator>
            <Separator>
              <small>Para retiros por recepción, indicar aquí la fecha que retirará, teniendo en cuenta el plazo publicado en el fechador.</small>
            </Separator>
            {state && state.formType == FormTypeEnum.Quote ? 
            <div>
            <Separator marginTop={25}>
              <p><b>Datos para el formulario de cotizacion al usuario</b></p>
              <hr/> 
            </Separator>  
            <Separator marginTop={5}>
              <span>Plazo de entrega</span>
              <FormItemComponent
                inputName={"plazo"}
                rules={[]}
              >
                <InputText
                  enabled
                  required={false}
                  showPlaceholder
                  type={"text"}
                  name={"plazo"}
                  placeholder={"Plazo de entrega"}
                  initialValue={ (dataToRender && dataToRender.plazo) || (configurationData && configurationData.params?.plazo) || undefined }
                />
              </FormItemComponent>
            </Separator>
            <Separator marginTop={5}>
              <span>Condición de pago</span>
              <FormItemComponent
                inputName={"condicion"}
                rules={[]}
              >
                <InputText
                  enabled
                  required={false}
                  showPlaceholder
                  type={"text"}
                  name={"condicion"}
                  placeholder={"Condición de pago"}
                  initialValue={ (dataToRender && dataToRender.condicion) || (configurationData && configurationData.params?.condicion) || undefined  }
                />
              </FormItemComponent>
            </Separator>  
            <Separator marginTop={5}>
              <span>Validez de precio</span>
              <FormItemComponent
                inputName={"validez"}
                rules={[]}
              >
                <InputText
                  enabled
                  required={false}
                  showPlaceholder
                  type={"text"}
                  name={"validez"}
                  placeholder={"Validez de precio"}
                  initialValue={ ( dataToRender && dataToRender.validez) || (configurationData && configurationData.params?.validez) || undefined }
                />
              </FormItemComponent>
            </Separator>  
            <Separator marginTop={5}>
              <span>Observaciones de la cotización</span>
              <FormItemComponent
                inputName={"observacionesAdicionales"}
                rules={[]}
              >
                <InputTextArea
                  enabled
                  required={false}
                  showPlaceholder
                  type={"text"}
                  name={"observacionesAdicionales"}
                  placeholder={"Observaciones de la cotización"}
                  initialValue={ (dataToRender && dataToRender.observacionesAdicionales) || (configurationData && configurationData.params?.observaciones) || undefined }
                  style={{ height: 45 }}
                />
              </FormItemComponent>
            </Separator>
            <Separator>
              <small>Ud. puede preestablecer estos datos desde “Mi configuración”</small>
            </Separator>  
            </div> : ""}      
            <Separator marginTop={15}>
              <Button
                type="primary"
                className={"custom-button custom-button-blue"}
                htmlType="submit"
              >
                { showSubmitText() }
              </Button>
            </Separator>
          </Form>
        </>
      )}
    </AuthenticatedRoute>
  );
};

export default ProductReferencePage;
