import { Input } from "antd";
import React, { useEffect, useState } from "react";
import { InputFloatLabelProps } from "../../../shared/types";
import { FloatLabel } from "../floatLabel";

export default function InputText(props: InputFloatLabelProps) {
  const [inputState, setInputState] = useState(undefined);
  const [className, setClassName] = useState<string>("form__input");

  const handleChange = (e: any, initialValue = false) => {
    const value = initialValue ? e : e.target.value;
    setInputState(value);
    props.onChange && props.onChange(value);
  };

  const onBlur = () => {
    if (props.required && props.required) {
      const classname = "form__input";
      if (
        inputState === "" ||
        inputState === null ||
        inputState === undefined
      ) {
        setClassName(classname + " form__label--error");
      } else {
        setClassName(classname);
      }
    }
  };

  useEffect(() => {
    handleChange(props.initialValue, true);
  }, [props.initialValue]);

  useEffect(() => {
    if (!props.required || !props.enabled) {
      setClassName("form__input");
    }
  }, [props.required, props.enabled]);

  const InputElement = <Input
                          type={props.type}
                          size={"large"}
                          disabled={!props.enabled}
                          prefix={props.prefix && props.prefix}
                          placeholder={props.showPlaceholder ? props.placeholder : ""}
                          className={`${props.classname} ${className} `}
                          value={inputState}
                          onChange={handleChange}
                          onBlur={!props.onBlur ? onBlur : undefined}
                          style={props.style && props.style}
                        />

  return (
    <div
      style={{ display: "flex", flexDirection: "column" }}
      className={"inputFormBorder"}
    >
      {props.floatLabel ? 
        <FloatLabel label={props.floatLabel} value={inputState}>
          {InputElement}
        </FloatLabel>
        :
        InputElement
      }
    </div>
  );
}
