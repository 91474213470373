import { IProductQuote, IProductQuoteAditionalComplements } from "../../core/models/products";

/**
 * 
 * @param newQuantity 
 * @param productQuoteIndex 
 * @param productQuote 
 * @param record 
 * 
 * Funcion que maneja agregar/actualizar/eliminar complementos
 * desde la vista de ComplementsPage
 */
const handleAditionalComplementChange = (
    newQuantity         : number,
    productQuoteIndex   : number,
    productQuote        : IProductQuote[],
    record              : IProductQuoteAditionalComplements
) => {

    let updated: IProductQuote[] = [];

    const newComplement:IProductQuoteAditionalComplements = {
        id          : record.id,
        quantity    : newQuantity,
        price       : record.price,
        productId   : record.productId,
        description : record.description,
        code        : record.code,
        itemOrderId : record.itemOrderId
      };
  
      if( newQuantity > 0 && record.exists )
      {
        updated = updateComplements(productQuote, productQuoteIndex, record, newComplement);
      }
      
      if( newQuantity > 0 && !record.exists )
      {
        updated = setComplement( productQuote, productQuoteIndex, newComplement );
      }
  
      if( !newQuantity )
      {
        updated = removeComplement( productQuote, productQuoteIndex, record );

      }

      return [ ...updated ]
};

/**
 * 
 * @param productQuote 
 * @param productQuoteIndex 
 * @param record 
 * @param newComplement 
 * @returns si el complemento existe y el nuevo valor es diferente de 0, este
 * se actualiza por el nuevo en productQuote.complementosAdicionales
 */
const updateComplements = (    
    productQuote:IProductQuote[],
    productQuoteIndex:number, 
    record:IProductQuoteAditionalComplements, 
    newComplement: IProductQuoteAditionalComplements
) => 
{
    return productQuote.map(( product:IProductQuote, productIndex:number ) => 
    {        
      if( productQuoteIndex == productIndex )
      {
        const { complementosAdicionales:aditionals } = product;
        return {
          ...product,
          complementosAdicionales : aditionals.map(( aditional:IProductQuoteAditionalComplements ) => {
              if(aditional.productId == record.productId )
              {
                return newComplement;
              }
              return aditional;
          })
        };
      }

      return product;
    });
}

/**
 * 
 * @param productQuote 
 * @param productQuoteIndex 
 * @param newComplement 
 * @returns si la cantidad es mayor a cero y este complemento no exista dentro del producto
 * del pedido, entonces setComplement lo agrega.
 */
const setComplement = (
    productQuote:IProductQuote[],
    productQuoteIndex:number, 
    newComplement: IProductQuoteAditionalComplements
) => 
{
    return productQuote.map(( product:IProductQuote, productIndex:number ) => 
    {        
      if( productQuoteIndex == productIndex )
      {
        return {
          ...product,
          complementosAdicionales : [
            ...product.complementosAdicionales,
            newComplement
          ]
        };
      }

      return product;
    });
}

/**
 * 
 * @param productQuote 
 * @param productQuoteIndex 
 * @param record 
 * @returns funcion que se encarga de removere del array de complementos, todos aquellos
 * cuya cantidad se haya establecido en cero.
 */
const removeComplement = (
    productQuote:IProductQuote[],
    productQuoteIndex:number, 
    record:IProductQuoteAditionalComplements, 
) => 
{
    return productQuote.map(( product:IProductQuote, productIndex:number ) => 
    {        
      if( productQuoteIndex == productIndex )
      {
        return {
          ...product,
          complementosAdicionales: product.complementosAdicionales.filter(( aditional ) => aditional.id != record.id )
        }
      }

      return product;
    });
}

const parseToComplementosAdicionales = ( quotation:any ) => {
    return {
        ...quotation,
        items : quotation.items.map(( item:any ) => {
            const { complementos, ...rest } = item;
            return {
                ...rest,
                complementosAdicionales: item.complementos.map((complement:any) => {
                    return {
                        id            : complement._id,
                        quantity      : complement.cantidad,
                        price         : complement.precio,
                        productId     : complement.producto._id,
                        description   : complement.producto.descripcion,
                        code          : complement.producto.codigo,
                        itemOrderId   : complement.item_pedido_id
                    };
                })
            }
        })
    }
}
export {
    handleAditionalComplementChange,
    removeComplement,
    parseToComplementosAdicionales
}