import React, { useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";

import Save from "../../assets/icon/save_alt.png";
import Editar from "../../assets/icon/create_24px.png";
import Anular from "../../assets/icon/anular.png";
import Ver from "../../assets/icon/visibility.png";

import { Paths } from "../../config/paths";
import AuthenticatedRoute from "../../core/routes/AuthenticatedRoute";
import { useDispatch, useSelector } from "react-redux";
import { Col, Form, FormInstance, message, Pagination, Row, Table } from "antd";
import { SimpleSearchForm } from "../../components/forms";
import { FormTypeEnum, InputNumberType, ReduxType } from "../../shared/types";
import { ColumnsType } from "antd/lib/table";
import { API_URL } from "../../config/general-config";
import { Endpoints } from "../../config/endpoints";
import {
  addQuoteToQuoteAction,
  cleanQuoteToQuoteAction,
  getQuoteSearchAction,
} from "../../store/quotes/actions";
import { DropdownMenu } from "../../components/dropdownMenu";
import { apiGet, apiPost, apiPut, getHeaders } from "../../shared/ApiService";
import { CustomModal } from "../../components/modal";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { FormItemComponent } from "../../components/forms/formItemComponent";
import { InputNumber, InputText } from "../../components/inputs";
import { getConfigurationParamsAction } from "../../store/configuration/actions";
import { ModalForm } from "../../components/modalForm";
import {
  getQuoteState,
  getStateImg,
} from "../../shared/helper";
import { getClientByIdAction } from "../../store/clients/actions";
import { hasPermission, isAdmin } from "../../core/auth/AuthService";
import { getUserInfoAction } from "../../store/balance/actions";
import NumberFormat from "react-number-format";

const QuotationPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isVisibleCancelPopup, setisVisibleCancelPopup] =
    useState<any>(undefined);
  const [isVisibleDownloadPopup, setisVisibleDownloadPopup] =
    useState<any>(undefined);
  const [currentSearchValue, setCurrentSearchValue] = useState<
    string | undefined
  >();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [verifyAdmin, setVerifyAdmin] = useState<boolean | undefined>(
    undefined
  );
  const [paginationHeight, setPaginationHeight] = useState(0);
  const paginationRef = useRef<any>(null);

  const formRefModal = React.createRef<FormInstance>();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { balanceData, configurationData, quoteData, userData } = useSelector(
    (state: ReduxType) => {
      return state;
    }
  );

  const getConfigurationData = async () => {
    const userId =
      userData &&
      userData.userProfile &&
      typeof userData.userProfile === "string"
        ? JSON.parse(userData.userProfile)
        : userData.userProfile;

    const finalId = userId ? userId._id : "";
    const url = `${API_URL}/${Endpoints.CONFIGURATION_BY_USER}${finalId}`;

    await dispatch(getConfigurationParamsAction(url));
  };

  useEffect(() => {
    if (verifyAdmin == undefined) {
      setVerifyAdmin(isAdmin());
    }
  }, [verifyAdmin]);

  useEffect(() => {
    getConfigurationData();
  }, []);

  useEffect(() => {
    if (paginationRef && paginationRef.current)
      setPaginationHeight(paginationRef.current.clientHeight);
  }, [paginationRef]);

  useEffect(() => {
    if (balanceData.user && !quoteData.quoteSearch) {
      doRequest(1, currentSearchValue);
    }
  }, [balanceData.user]);

  useEffect(() => {
    if (balanceData.user && currentPage) {
      doRequest(currentPage, currentSearchValue);
    }
  }, [currentPage]);

  useEffect(() => {
    if (balanceData.user && currentSearchValue) {
      doRequest(1, currentSearchValue);
    }
  }, [currentSearchValue]);

  useEffect(() => {
    if (!balanceData.user) {
      getUserData();
    }
  }, []);

  const getUserData = async () => {
    const url = `${API_URL}/${Endpoints.USER}`;
    await dispatch(getUserInfoAction(url));
  };

  const doRequest = async (page: number, searchValue: string | undefined) => {
    let url = `${API_URL}/${Endpoints.SAVE_GET_QUOTE}?`;

    if (!isAdmin()) {
      const clientId = balanceData && balanceData.user?._id;
      url += `cliente_id=${clientId}&&`;
    }

    if (searchValue) {
      url += `referencia=${searchValue}`;
    }
    if (page) {
      url += `${searchValue ? "&" : ""}page=${page}&pageSize=12`;
    }

    await dispatch(getQuoteSearchAction(url));
  };

  const doCancelRequest = async (id: any) => {
    const url = `${API_URL}/${Endpoints.CANCEL_QUOTE}${id}`;

    const request = await apiPost({
      url,
      body: {},
      unauthorizedCallback: () => {},
    });

    request && doRequest(currentPage, currentSearchValue);
    request && setisVisibleCancelPopup(undefined);
  };

  const handleViewAndEditionAction = async (
    quote: any,
    editMode: boolean,
    canEdit: boolean
  ) => {
    const url = `${API_URL}/${Endpoints.QUOTE_SEARCH}/${quote._id}`;
    await dispatch(getClientByIdAction(quote.cliente._id));
    await dispatch(addQuoteToQuoteAction(url));
    navigate(Paths.PRODUCTDETAILLIST, {
      state: {
        from: Paths.QUOTATION,
        formType: FormTypeEnum.Quote,
        editMode: editMode,
        canEdit: canEdit,
      },
    });
  };

  const handleCancelAction = (value: any) => {
    setisVisibleCancelPopup(value);
  };

  const handleDownloadAction = async (value: any) => {
    setisVisibleDownloadPopup({
      ...value,
      rentabilidad: configurationData.params.rentabilidad.toFixed(2),
    });
  };

  const columns = [
    {
      title: "Referencia",
      dataIndex: "referencia",
      show: true
    },
    {
      title: "Costo Final",
      dataIndex: "costoFinal",
      show: hasPermission('READ_FINAL_COST'),
      render: (value:any) => <span>
                          <NumberFormat 
                            value={value} 
                            displayType={'text'} 
                            thousandSeparator={true}
                            decimalScale={2} 
                            fixedDecimalScale={true}
                            prefix={'$'} />
                          </span>
    },
    {
      title: "Estado",
      dataIndex: "estado",
      show: true
    },
    {
      title: "Acciones",
      dataIndex: "acciones",
      show: true,
      render: (value:any, record:any, index:any) => {
        const canEdit = getQuoteState(record.estadoCotizacion);
        const menu = [
          {
            name: "Ver y/o generar pedido",
            icon: <img src={Editar} alt="edit-icon" />,
            action: () =>
              handleViewAndEditionAction(record.quote, true, canEdit),
            disabled: !canEdit,
          },
          {
            name: "Descargar",
            icon: <img src={Save} alt="save-icon" />,
            action: () => handleDownloadAction(record),
          },
        ];

        if (verifyAdmin != undefined && verifyAdmin) {
          getQuoteState(record.estadoCotizacion) &&
            menu.push({
              name: "Anular",
              icon: <img src={Anular} alt="cancel-icon" />,
              action: () =>
                getQuoteState(record.estadoCotizacion) &&
                handleCancelAction(value),
            } as any);
        }

        return (
          <div style={{ textAlign: "center", fontSize: 15 }}>
            <DropdownMenu menu={menu} />
          </div>
        );
      },
    },
  ].filter((row)=> {
    return row.show;
  });

  const dataSourceTable: any[] | undefined =
    quoteData.quoteSearch &&
    quoteData.quoteSearch.data &&
    quoteData.quoteSearch.data.map((col: any, index: number) => {
      return {
        key: index,
        _id: col._id,
        referencia: col.referencia,
        costoFinal: (col.total - (col.total * col.bonificacion / 100)),
        estadoCotizacion: col.estado_cotizacion,
        estado: getStateImg(col, FormTypeEnum.Quote),
        quote: col,
        acciones: col._id,
      };
    });

  const downloadPDF = async ( endpoint:string, fileName: string) => {

    const url = `${API_URL}/${endpoint}${isVisibleDownloadPopup._id}/rentabilidad/${isVisibleDownloadPopup.rentabilidad}`

    try {
      fetch(url, {
        headers: getHeaders(),
        method: "GET",
      }).then(async (value) => {
        return await value.blob().then((b: any) => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(b);
          link.setAttribute("download", fileName);
          link.click();
        });
      });
    } catch (error: any) {
      message.error(error.message, 8);
      return;
    }
  };

  const makeRequests = async (values: any) => {
    const updateClient = apiPut({
      url: `${API_URL}/${Endpoints.CLIENTS_PARAMETERS}/${configurationData.params._id}`,
      unauthorizedCallback: () => {},
      body: {
        ...configurationData.params
      },
    });
    const updateQuote = apiPut({
      url: `${API_URL}/${Endpoints.UPDATE_QUOTE}${isVisibleDownloadPopup._id}`,
      unauthorizedCallback: () => {},
      body: {
        ...values.quoteDetails,
        rentabilidad: values.rentabilidad,
      },
    });
    setIsSubmitting(true);
    await Promise.all([
      updateClient,
      updateQuote,
      downloadPDF(
        Endpoints.GET_PDF_USUARIO,
        `Cotiz Usuario sin detalle ${isVisibleDownloadPopup._id}.pdf`
      ),
      downloadPDF(
        Endpoints.GET_PDF_USUARIO_WITH_ATTRIBUTES,
        `Cotiz Usuario con detalle ${isVisibleDownloadPopup._id}.pdf`
      )
    ]);
    setisVisibleDownloadPopup(undefined);
    setIsSubmitting(false);
  };

  const onDownloadSubmit = async (values: any) => {
    const url = `${API_URL}/${Endpoints.SAVE_GET_QUOTE}/${isVisibleDownloadPopup._id}`;
    const quoteDetails = await apiGet({ url, unauthorizedCallback: () => {} });
    makeRequests({ values, quoteDetails });
  };

  const cleanAndNavigate = async () => {
    await dispatch(cleanQuoteToQuoteAction());
    navigate(Paths.ROOT);
  };

  return (
    <AuthenticatedRoute
      path={Paths.QUOTATION}
      withHeader
      goBack={() => cleanAndNavigate()}
      headerNavigationName={"Mis Cotizaciones"}
    >
      {!isLoading && (
        <>
          <SimpleSearchForm
            inputName={"referenciaSearch"}
            placeholder={"Referencia"}
            onSearch={(value) => setCurrentSearchValue(value)}
          />

          <Row
            align="middle"
            justify="space-between"
            style={{ marginBottom: paginationHeight }}
          >
            <Col span={24}>
              <Table
                id={"mytable"}
                loading={quoteData && quoteData.loading}
                columns={columns}
                dataSource={dataSourceTable}
                pagination={false}
                className={"align-center-table-header"}
                rowClassName={"max-width-cell align-center-table-cell"}
              />
            </Col>
          </Row>
          {quoteData.quoteSearch &&
            quoteData.quoteSearch.data &&
            quoteData.quoteSearch.data.length > 0 && (
              <div
                style={{
                  padding: 10,
                  backgroundColor: "#f0f0f0",
                  position: "fixed",
                  bottom: 0,
                  width: "100%",
                }}
                ref={paginationRef}
              >
                <Pagination
                  pageSize={12}
                  style={{ float: "right" }}
                  current={currentPage}
                  onChange={(page) => setCurrentPage(page)}
                  total={quoteData.quoteSearch.total}
                  showSizeChanger={false}
                />
              </div>
            )}
        </>
      )}
      <CustomModal
        contentMessage="Se anulará la cotización."
        closable={false}
        showOk={true}
        showCancel={false}
        handleOk={() => doCancelRequest(isVisibleCancelPopup)}
        handleCancel={() => setisVisibleCancelPopup(undefined)}
        isModalVisible={!!isVisibleCancelPopup}
        antdIcon={
          <>
            <ExclamationCircleFilled
              style={{ color: "#F59422", height: 45, width: 45 }}
              className="custom-modal_icon"
            />
          </>
        }
      />
      <ModalForm
        title="Descargar Cotización"
        handleCancel={() => setisVisibleDownloadPopup(undefined)}
        cancelText="Cancelar"
        okText="Descargar"
        isModalVisible={!!isVisibleDownloadPopup}
        isSubmitting={isSubmitting}
      >
        {!!isVisibleDownloadPopup && (
          <Form
            name="modal-form"
            initialValues={undefined}
            layout="vertical"
            onFinish={onDownloadSubmit}
            ref={formRefModal}
          >
            <FormItemComponent
              inputName="referencia"
              rules={[{ name: "required" }]}
            >
              <InputText
                enabled
                initialValue={isVisibleDownloadPopup.referencia}
                name="referencia"
                floatLabel="Refencia"
                required
                type="text"
                style={{ padding: "0px 22px !important" }}
              />
            </FormItemComponent>
            {
              !hasPermission('GRILLA_VENDEDOR') ? <FormItemComponent
              inputName="rentabilidad"
              rules={[{ name: "required" }]}
            >
              <InputNumber
                enabled
                initialValue={isVisibleDownloadPopup.rentabilidad}
                name="rentabilidad"
                floatLabel="Rentabilidad (%)"
                required
                type={InputNumberType.Decimal2}
                onChange={(e) => {
                  setisVisibleDownloadPopup({ ...isVisibleDownloadPopup, rentabilidad: e })
                }}
              />
            </FormItemComponent> : null
            }
          </Form>
        )}
      </ModalForm>
    </AuthenticatedRoute>
  );
};

export default QuotationPage;
